export const ROMANS: string[] = ['N', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII'];

export const wgsdkThousands = (num: any, reduce?: boolean, startFrom?: any) => {
  let result;
  let i = 0;

  if (reduce && startFrom <= num) {
    let suffix = '';

    if (num >= 1000000) {
      num /= 1000000;
      suffix = ' M';
    } else if (num >= 100000) {
      num /= 1000;
      suffix = ' K';
    }
    result = num;
    if (suffix) {
      result = num.toFixed(2) + suffix;
    }
    return result;
  }

  let dotted = '';
  num = num.toString();
  const dotPosition = num.search(/\./);
  if (dotPosition > -1) {
    dotted = '.';
    dotted += num.substr(dotPosition + 1);
    num = num.substr(0, dotPosition);
  }

  result = '';

  let sign = '';
  if (num.substr(0, 1) === '-') {
    num = num.substr(1);
    sign = '-';
  }

  const len = num.length;
  const separator = ' ';
  for (i = 0; i < len; ++i) {
    if (i !== 0 && len - i !== 0 && (len - i) % 3 === 0) {
      result += separator;
    }
    result += num.charAt(i);
  }
  return sign + result + dotted;
};

export const thousands = (value: any, reduceStartFrom?: any) => {
  value = Math.round(parseFloat(value));
  if (isNaN(value)) {
    return '-';
  }
  return wgsdkThousands(value, !!reduceStartFrom, reduceStartFrom);
};

export const toRoman = (value: number) => {
  return ROMANS[value];
};

export const round = (value: number, ndigits: number) => {
  const shift = Math.pow(10, ndigits);
  return Math.round(value * shift) / shift;
};

const formatNumberDefault = (num: number | string): string => {
  if (typeof num === 'number') num = num.toLocaleString('en');
  return num.replace(/[,|.]/g, (separator) => (separator === ',' ? '\u00A0' : ','));
};

export const formatNumber = (num: number | string): string =>
  (window?.formatNumber || window?.format_number || formatNumberDefault)(num);
