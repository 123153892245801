import * as React from 'react';
import ReactDOM from 'react-dom';

export interface IProps {
    str: string;
    [key: string]: string | number | React.ReactNode;
}

export interface IState {

}

class Interpolate extends React.Component<IProps, IState> {

    public s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);

    public hash = this.s4();

    public componentDidMount() {
        this.updateContent();
    }

    public componentDidUpdate(prevProps: IProps, prevState: IState) {
        this.updateContent();
    }

    public updateContent = () => {
        let i = 0;
        // @ts-ignore
        this.props.str.replace(/%\(\w+\)s/g, (match: any) => {
            i++;
            const selector = match.replace('%(', '').replace(')s', '');
            if (this.props[selector]) {
                const component = this.props[selector];
                const place = document.getElementById(`ipl-${selector}-${this.hash}-${i}`);
                if (place && component) {
                    // @ts-ignore
                    ReactDOM.render(component, place);
                }
            }
        });
    }

    public getInnerHtml = () => {
        let i = 0;
        return this.props.str.replace(/%\(\w+\)s/g, (match: any) => {
            i++;
            const selector = match.replace('%(', '').replace(')s', '');
            return `<div style="display: inline-block" id="ipl-${selector}-${this.hash}-${i}"></div>`;
        });
    }

    public render() {
        return (
            <div dangerouslySetInnerHTML={{ __html: this.getInnerHtml() }} />
        );
    }
}

export default Interpolate;