import { sendAction } from '@/core/action';
import { playSound } from '@/core/play';


export function playDropdownSound() {
  return playSound({
    soundsetName: 'dropmenu_button',
    action: 'click',
  })
}

export function playOpenDialogSound() {
  return playSound({
    soundsetName: 'default_window',
    action: 'open',
  })
}

export function playCloseDialogSound() {
  return playSound({
    soundsetName: 'default_window',
    action: 'close',
  })
}

export function playInputSound() {
  return playSound({
    soundsetName: 'default_textinput',
    action: 'change',
  })
}

export function playCheckboxSound() {
  return playSound({
    soundsetName: 'default_checkbox',
    action: 'click',
  })
}

export function playButtonSound() {
  return playSound({
    soundsetName: 'default_button',
    action: 'press',
  })
}

export function playProgressBarSectionOpenSound() {
  return playSound({
    soundsetName: 'default_progress_bar',
    action: 'press',
  })
}

export function playBoosterWindowOpen() {
  return playSound({
    soundsetName: 'default_window_buster',
    action: 'open',
  })
}

export function playBoosterBuyRegular() {
  return playSound({
    soundsetName: 'default_booster',
    action: 'buy',
  })
}

export function playBoosterPressRegular() {
  return playSound({
    soundsetName: 'default_booster',
    action: 'press',
  })
}

export function playBoosterBuyPremium() {
  return playSound({
    soundsetName: 'default_prem_booster',
    action: 'buy',
  })
}

export function playBoosterPressPremium() {
  return playSound({
    soundsetName: 'default_prem_booster',
    action: 'press',
  })
}

export function playShipBuildAnimationStart() {
  return playSound({
    soundsetName: 'default_build_ship',
    action: 'start',
  })
}

export function playShipBuildAnimationEnd() {
  return playSound({
    soundsetName: 'default_build_ship',
    action: 'end',
  })
}

export function playBoosterBuildAnimationStart() {
  return playSound({
    soundsetName: 'default_build_building',
    action: 'start',
  })
}

export function playBoosterBuildAnimationEnd() {
  return playSound({
    soundsetName: 'default_build_building',
    action: 'end',
  })
}

export function playShipYardOver() {
  return playSound({
    soundsetName: 'default_shipyard_over',
    action: 'over',
  })
}

export function playShipYardSoftOver() {
  return playSound({
    soundsetName: 'default_shipyard_over',
    action: 'soft_over',
  })
}

export function playBuyoutOver() {
  return playSound({
    soundsetName: 'default_ship_buyout',
    action: 'over',
  })
}

export function playBuyoutOut() {
  return playSound({
    soundsetName: 'default_ship_buyout',
    action: 'out',
  })
}

export function shipyardSoundOn() {
  return playSound({
    soundsetName: 'default_shipyard_sound',
    action: 'on',
  })
}

export function shipyardSoundOff() {
  return playSound({
    soundsetName: 'default_shipyard_sound',
    action: 'off',
  })
}

export function changeShipyardStage() {
  return playSound({
    soundsetName: 'default_shipyard_stage',
    action: 'change',
  })
}

export function playCrewRandomVOSample(crewId: number) {
  return sendAction({
    actionId: 'playCrewRandomVOSample',
    crewId,
  });
}

export function playButtonClickSound() {
  return playSound({
    soundsetName: 'button_secondary',
    action: 'click',
  })
}

export function playButtonDialogClickSound() {
  return playSound({
    soundsetName: 'button_default',
    action: 'click',
  })
}

export function playButtonWalletClickSound() {
  return playSound({
    soundsetName: 'button_credits',
    action: 'click',
  })
}

export function playButtonGoldClickSound() {
  return playSound({
    soundsetName: 'button_gold',
    action: 'click',
  })
}

export function playCheckboxClickSound() {
  return playSound({
    soundsetName: 'check_box',
    action: 'click',
  })
}

export function playDropdownClickSound() {
  return playSound({
    soundsetName: 'dropdown',
    action: 'click',
  })
}

export function playTabClickSound() {
  return playSound({
    soundsetName: 'button_tab',
    action: 'click',
  })
}

export function playPaperCardClickSound() {
  return playSound({
    soundsetName: 'button_card',
    action: 'click',
  })
}

export function playPopupOpenSound() {
  return playSound({
    soundsetName: 'default_screen',
    action: 'change',
  })
}

export function playCardOverSound() {
  return playSound({
    soundsetName: 'button_flat',
    action: 'over',
  })
}

export function playCardClickSound() {
  return playSound({
    soundsetName: 'button_flat',
    action: 'click',
  })
}

export function playExchangeSound() {
  return playSound({
    soundsetName: 'default_exchange',
    action: 'exchange',
  })
}

export function playSoldSound() {
  return playSound({
    soundsetName: 'default_messages',
    action: 'sold',
  })
}

export default {
  playDropdownSound,
  playOpenDialogSound,
  playCloseDialogSound,
  playInputSound,
  playCheckboxSound,
  playButtonSound,
  playProgressBarSectionOpenSound,
  playBoosterWindowOpen,
  playBoosterBuyRegular,
  playBoosterBuyPremium,
  playShipBuildAnimationStart,
  playShipBuildAnimationEnd,
  playBoosterBuildAnimationStart,
  playBoosterBuildAnimationEnd,
  playBoosterPressRegular,
  playBoosterPressPremium,
  playShipYardOver,
  playShipYardSoftOver,
  playBuyoutOver,
  playBuyoutOut,
  shipyardSoundOn,
  shipyardSoundOff,
  changeShipyardStage,
  playCrewRandomVOSample,
  playButtonClickSound,
  playButtonDialogClickSound,
  playButtonWalletClickSound,
  playButtonGoldClickSound,
  playCheckboxClickSound,
  playDropdownClickSound,
  playTabClickSound,
  playPaperCardClickSound,
  playPopupOpenSound,
  playCardOverSound,
  playCardClickSound,
  playExchangeSound,
  playSoldSound
}
