type consoleType = keyof typeof console;

export const isTestEnvironment = process.env.NODE_ENV === 'test';

export const isDevEnvironment = process.env.NODE_ENV === 'development';

export const isDebug = isTestEnvironment || isDevEnvironment;

function logger(type: consoleType, ...args: any[]) {
  console[type]('[web2client]', ...args);
}

export function log(...args: any[]) {
  if (isDebug) {
    logger('info', ...args);
  }
}

export function warn(...args: any[]) {
  logger('warn', ...args);
}

export function error(...args: any[]) {
  logger('error', ...args);
}
