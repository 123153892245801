import classNames from 'classnames';
import * as React from 'react';
import styles from './Tooltip.scss';

export interface IProps {
    children?: React.ReactNode;
    mouseIcon: 'left' | 'right';
}

const TooltipFooter = (props: IProps) => {
    const classNameFooter = classNames(styles.footer, styles[props.mouseIcon]);

    return (
        <div className={classNameFooter}>{props.children}</div>
    );
};

export default TooltipFooter;
