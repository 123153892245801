import web2clientapi from '@wg/web2clientapi';
import * as React from 'react';
import styles from './Toggle.scss';

export type IProps = {
    caption: string,
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
};

class Toggle extends React.PureComponent<IProps> {

    public onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        web2clientapi.sounds.playButtonSound();
        this.props.onClick(event);
    }

    public render() {
        return (
            <div className={styles.toggle} onClick={this.onClick}>{this.props.caption}</div>
        );
    }
}

export default Toggle;
