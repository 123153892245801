import { sendCommand } from '@/core/command';
import { log } from '@/utils/logger';


export interface PlaySoundParams {
  soundsetName: string,
  action: string,
}

export function playSound({ soundsetName, action }: PlaySoundParams) {
  log('Calling play action', soundsetName, action);

  return sendCommand({
    command: 'sound',
    params: {
      soundsetName,
      action,
    },
  });
}
