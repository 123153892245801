import classNames from 'classnames';
import * as React from 'react';
import styles from './ClanRole.scss';

export interface IClanRole {
    role: string;
    isLarger?: boolean;
}

const ClanRole = (props: IClanRole) => (
    <i className={
        classNames(styles[props.role], {
            [styles.isLarger]: props.isLarger,
        })
    }/>
);

ClanRole.defaultProps = {
    isLarger: false,
};

export default ClanRole;
