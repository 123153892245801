import * as React from 'react';
import styles from './PageTitle.scss';

export interface IProps {
    title: string | React.ReactNode;
    subTitle: string;
}

class PageTitle extends React.PureComponent<IProps> {

    public render() {
        const subTitle = this.props.subTitle ? <div className={styles.subTitle}>{this.props.subTitle}</div> : null;

        return (
            <div className={styles.container}>
                <div className={styles.title}>{this.props.title}</div>
                {subTitle}
            </div>
        );
    }
}

export default PageTitle;
