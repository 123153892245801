export interface IDataObj {
    [key: string]: string | number | undefined;
    count?: number;
}

export const t = (str: string, obj?: IDataObj) => {
    if (window.i18n) {
        if (obj) {
            if (obj.count !== undefined) {
                return window.i18n.interpolate(window.i18n.ngettext(str, str, obj.count), obj, true);
            } else {
                return window.i18n.interpolate(window.i18n.gettext(str), obj, true);
            }
        } else {
            return window.i18n.gettext(str);
        }
    } else {
        return str;
    }
};

export const x = (str: string) => {

    if (window.i18n) {
        return window.i18n.gettext(str);
    } else {
        return str;
    }
};

export default {
    t,
    x,
};
