import * as React from 'react';
import styles from './Sticky.scss';

export interface IProps {
    containerId: string;
    children: React.ReactNode;
}
class StickyHeader extends React.Component<IProps> {

    public container: HTMLElement | null = null;

    public sticky: HTMLDivElement;

    public componentDidMount() {
        this.container = document.getElementById(this.props.containerId);
        if (this.container) {
            this.container.addEventListener('scroll', this.onScroll);
        }
    }

    public onScroll = (e: Event) => {
        if (this.container) {
            const translateY = this.container.scrollTop;
            if (this.sticky) {
                this.sticky.style.transform = `translate(0, ${translateY}px)`;

                if (translateY === 0) {
                    this.sticky.classList.remove(styles.headerWrapperActive);
                } else {
                    this.sticky.classList.add(styles.headerWrapperActive);
                }
            }
        }
    }

    public render() {
        return (
            <div
                className={styles.headerWrapper}
                ref={(r) => {
                    if (r) {
                        this.sticky = r;
                    }
                }}
            >
                {this.props.children}
            </div>
        );
    }
}

export default StickyHeader;
