import * as React from 'react';
import { t } from '../../helpers/localization';
import ClanName from '../ClanName/ClanName';
import styles from './TooltipClan.scss';

export interface IProps {
    clanTag: string;
    clanColor: string;
    clanName: string;
    isDisbanded?: boolean;
}

class TooltipClan extends React.PureComponent<IProps> {

    public render() {

        const disbandedMessage = this.props.isDisbanded ? <div className={styles.disbandedText}>{t('Клан распущен')}</div> : null;

        return (
            <div className={styles.container}>
                <div>
                    <ClanName
                        clanTag={this.props.clanTag}
                        clanColor={this.props.clanColor}
                        clanName={this.props.clanName}
                    />
                </div>
                {disbandedMessage}
            </div>
        );
    }
}

export default TooltipClan;
