import classNames from 'classnames';
import * as React from 'react';
import { t } from '../../helpers/localization';
import HiddenStatisticsIcon from '../HiddenStatisticsIcon/HiddenStatisticsIcon';
import MemberName from '../MemberName/MemberName';
import styles from './ClanMember.scss';


export interface IPropTypes {
    name: string;
    hasPermanentBan: boolean;
    isHiddenStatistics: boolean;
    isOwn: boolean;
    isHighlightedAdmittance: boolean;
    role: string;
    onContextMenu?: () => void;
    memberIsBannedTooltipId?: string;
    disableTooltip?: boolean;
}

class ClanMember extends React.PureComponent<IPropTypes> {
    public render() {
        const role = this.props.role
            ? <div className={styles.memberRole}>{t(this.props.role)}</div>
            : null;

        // let tooltipId = null
        // let dataTip = true
        // if (!this.props.disableTooltip) {
        //     tooltipId = this.props.hasPermanentBan ? this.props.memberIsBannedTooltipId : 'member-tooltip'
        //     dataTip = tooltipId === 'member-tooltip' ? this.props.id : true
        // }

        const hiddenStatisticsIcon = this.props.isOwn && this.props.isHiddenStatistics ? (
            <div className={styles.hiddenStatisticsIcon}>
                <HiddenStatisticsIcon isOwnAccount />
            </div>
        ) : null;

        const classNameMember = classNames(styles.member, {
            [styles.isOwn]: this.props.isOwn,
            [styles.isHiddenStatistics]: this.props.isOwn && this.props.isHiddenStatistics,
        });

        const classNameMemberName = classNames(styles.memberName, {
            [styles.isHighlightedAdmittance]: this.props.isHighlightedAdmittance,
        });

        return (
            <div className={classNameMember} onContextMenu={this.props.onContextMenu}>
                {hiddenStatisticsIcon}
                <div className={classNameMemberName}>
                    <MemberName
                        name={this.props.name}
                        isInline
                    />
                </div>
                {role}
            </div>
        );
    }
}

export default ClanMember;
