import classNames from 'classnames';
import * as React from 'react';
import styles from './Sticky.scss';

export interface IProps {
    appContainerId: string;
    scrollContainerId: string;
    usedStickyContainerId: string;
    children?: React.ReactNode;

    breakpointOffset?: number;
    isActive?: boolean;
    forBottom?: boolean;

    onStickyStateChange?: (isSticky: boolean) => void;
}

export interface IState {
    isSticky: boolean;
    top?: number | undefined;
    left?: number | undefined;
    width?: number | undefined;
}

class Sticky extends React.Component<IProps> {

    private appContainer: HTMLElement | any;

    private stickyContainer: HTMLElement | any;

    private scrollContainer: HTMLElement | any;

    private children: HTMLElement | any;

    private placeholder: HTMLElement | any;

    public state: IState = {
        isSticky: false,
    };

    public componentDidMount() {
        this.appContainer = document.getElementById(this.props.appContainerId);
        this.stickyContainer = document.getElementById(this.props.usedStickyContainerId);
        this.scrollContainer = document.getElementById(this.props.scrollContainerId);
        this.on();
        this.recomputeState();
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
        let containerChanged = false;
        if (this.props.usedStickyContainerId !== nextProps.usedStickyContainerId) {
            containerChanged = true;
            this.stickyContainer = document.getElementById(nextProps.usedStickyContainerId);
        }

        if (this.props.appContainerId !== nextProps.appContainerId) {
            containerChanged = true;
            this.appContainer = document.getElementById(nextProps.appContainerId);
        }

        if (this.props.scrollContainerId !== nextProps.scrollContainerId) {
            containerChanged = true;
            this.scrollContainer = document.getElementById(nextProps.scrollContainerId);
            this.off().on();
        }

        if (containerChanged || this.props.isActive !== nextProps.isActive) {
            this.recomputeState(nextProps.isActive);
        }
    }

    public componentWillUnmount() {
        this.off();
        this.onStickyStateChange(false);
    }

    public shouldComponentUpdate = (newProps: IProps, newState: IState) => {
        const propNames = Object.keys(this.props);
        if (Object.keys(newProps).length !== propNames.length) return true;

        const valuesMatch = propNames.every((key) => {
            return newProps.hasOwnProperty(key) && newProps[key] === this.props[key];
        });
        if (!valuesMatch) return true;

        if (this.state.isSticky) {
            if (newState.top !== this.state.top) return true;
            if (newState.left !== this.state.left) return true;
            if (newState.width !== this.state.width) return true;
        }
        return newState.isSticky !== this.state.isSticky;
    }

    public onStickyStateChange = (isSticky: boolean) => {
        if (this.props.isActive && !this.props.forBottom) {
            const height = this.children ? this.children.getBoundingClientRect().height : 0;
            if (this.appContainer) {
                this.appContainer.style.paddingTop = isSticky ? `${height}px` : 0;
            }
        }
        if (this.props.onStickyStateChange) {
            this.props.onStickyStateChange(isSticky);
        }
    }

    public getDistanceFromTop = () => {
        if (this.stickyContainer) {
            return this.stickyContainer.getBoundingClientRect().top - this.scrollContainer.getBoundingClientRect().top;
        } else {
            return 0;
        }
    }

    public getDistanceFromBottom = () => {
        if (this.stickyContainer) {
            return this.stickyContainer.getBoundingClientRect().bottom - this.scrollContainer.getBoundingClientRect().bottom;
        } else {
            return 0;
        }
    }

    public isSticky = () => {
        if (this.props.forBottom) {
            const topBreakpoint = this.stickyContainer.getBoundingClientRect().top;
            const bodyStyle = window.getComputedStyle(document.body, null);
            const offset = parseInt(bodyStyle.paddingBottom ? bodyStyle.paddingBottom : '0', 10) + parseInt(bodyStyle.paddingBottom ? bodyStyle.paddingBottom : '0', 10) + this.children ? this.children.getBoundingClientRect().height : 0;
            return (
                this.getDistanceFromBottom() > 0 &&
                this.scrollContainer.scrollTop + this.scrollContainer.getBoundingClientRect().bottom - offset > topBreakpoint
            );
        } else {
            const bottomBreakpoint = this.scrollContainer.getBoundingClientRect().top;
            return (
                this.getDistanceFromTop() < 0 &&
                this.stickyContainer.getBoundingClientRect().bottom > bottomBreakpoint
            );
        }
    }

    public recomputeState = (isActive: boolean = false) => {
        const isSticky = (isActive || this.props.forBottom) && this.isSticky();
        if (isSticky !== this.state.isSticky) {
            this.onStickyStateChange(!!isSticky);
        }
        const width = this.placeholder ? this.placeholder.getBoundingClientRect().width : 0;
        const left = this.placeholder ? this.placeholder.getBoundingClientRect().left : 0;
        const newState: IState = { isSticky: !!isSticky, width, left };

        if (!this.props.forBottom && this.scrollContainer && this.children) {
            newState.top = this.scrollContainer.getBoundingClientRect().top - this.children.getBoundingClientRect().height;
        }

        this.setState(newState);
    }

    public onSmthChanged = () => {
        this.recomputeState(this.props.isActive);
    }

    public on = () => {
        if (this.scrollContainer) {
            this.scrollContainer.addEventListener('scroll', this.onSmthChanged);
        }
        window.addEventListener('resize', this.onSmthChanged);
        return this;
    }

    public off = () => {
        if (this.scrollContainer) {
            this.scrollContainer.removeEventListener('scroll', this.onSmthChanged);
        }
        window.removeEventListener('resize', this.onSmthChanged);
        return this;
    }

    public render() {
        let style: React.CSSProperties = {};
        let placeholderStyle: any = {};

        if (this.state.isSticky) {
            style = {
                left: this.state.left,
                width: this.state.width,
            };

            if (this.props.forBottom) {
                placeholderStyle = {
                    height: this.children.getBoundingClientRect().height,
                };
            } else {
                style.top = this.state.top;
            }
        }

        const classNameSticky = classNames(styles.sticky, {
            [styles.isSticky]: this.state.isSticky,
            [styles.forBottom]: this.props.forBottom,
            [styles.isActive]: this.props.forBottom && this.props.isActive,
        });

        const classNameDecor = classNames(styles.decor, {
            [styles.isActive]: this.state.isSticky && this.props.forBottom,
        });

        return (
            <div>
                <div
                    ref={(placeholder) => this.placeholder = placeholder}
                    style={placeholderStyle}
                />
                <div
                    className={classNameSticky}
                    ref={(children) => this.children = children}
                    style={style}
                >
                    <div className={classNameDecor} />
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Sticky;
