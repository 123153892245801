import * as React from 'react';
import { thousands } from '../../helpers/formatting';

import styles from './Gold.scss';
import classNames from "classnames";

export interface IProps {
    amount: number;
    isFlat?: boolean;
}

const Gold = (props: IProps) => (
    <div className={classNames(styles.gold, {[styles.isFlat]: !!props.isFlat})}>{thousands(props.amount)}</div>
);

export default Gold;
