import * as React from 'react';
import styles from './IconTable.scss';

export type IconTableTypes = 'battles-per-day' | 'battles' | 'clan' | 'clan-level' | 'clan-resource' | 'damage-per-battle' | 'days-in-clan' | 'experience-per-battle' | 'frags-per-battle' | 'members-count' | 'last-battle-time' | 'location' | 'rating' | 'roles' | 'wins-per-bettles' | 'search';
export interface IProps {
    glyph: IconTableTypes;
    onClick?: () => void;
}

class IconTable extends React.PureComponent<IProps, any> {

    public onClick = (event: React.MouseEvent<HTMLElement>) => {
        if (this.props.onClick) {
            this.props.onClick();
            event.stopPropagation();
        }
    }

    public render() {
        return (
            <i className={styles[this.props.glyph]} onClick={this.onClick} />
        );
    }
}

export default IconTable;
