import * as React from 'react';
import styles from './Spinner.scss';

export interface IProps {
    label?: string;
}

const Spinner = (props: IProps) => (
    <div className={styles.container}>
        <div className={styles.spinner}/>
        {props.label && <div className={styles.label}>{props.label}</div>}
    </div>
);

export default Spinner;
