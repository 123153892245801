import web2clientapi from '@wg/web2clientapi';
import classNames from 'classnames';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { t } from '../../helpers/localization';
import styles from './Dialog.scss';

const DEFAULT_ROOT_ID = 'modal-root';
const dialogFadeLeaveTimeout = 300;
const overlayFadeEnterTimeout = 300;
const overlayFadeLeaveTimeout = 300;

const {
    playButtonSound,
    playCloseDialogSound,
    playOpenDialogSound,
} = web2clientapi.sounds;

const overlayKey = 'overlay-fade'
const overlayName = 'overlay-fade'
const dialogKey = 'dialog-fade'
const dialogName = 'dialog-fade'

export type DialogSize = 'small' | 'large' | 'extra-large' | 'extra-height' | 'fullscreen' | 'overlay' | 'introduction' | 'info' | 'worship';

export interface IProps {
    content: React.ReactNode;
    size: DialogSize;
    isOverlay: boolean;
    isVisible: boolean;
    className?: string;
    rootId?: string;
    hideDialog: () => void;
}

class Dialog extends React.Component<IProps> {

    public componentDidMount() {
        document.addEventListener('keydown', this.onEscape, true);
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
        if (nextProps.isVisible !== this.props.isVisible) {
            nextProps.isVisible ? playOpenDialogSound() : playCloseDialogSound();
        }
    }

    public componentWillUnmount() {
        document.removeEventListener('keydown', this.onEscape, true);
    }

    public onEscape = (e: KeyboardEvent) => {
        if (e.keyCode === 27 && this.props.isVisible) {
            e.preventDefault();
            if (this.props.size !== 'introduction') {
                this.props.hideDialog();
            }
        }
    }

    public render() {
        const id = this.props.rootId ? this.props.rootId : DEFAULT_ROOT_ID;
        const modalRoot = window.document.getElementById(id);

        let overlay = null;
        let dialog = null;
        if (this.props.isVisible) {
            const classNameDialog = classNames(styles.dialog, styles[`dialog__${this.props.size}`]);
            const dialogContent = this.props.content ? this.props.content : null;
            const showCloseButton = !this.props.isOverlay && this.props.size !== 'introduction';

            const close = showCloseButton ? (
                <div
                    key="close"
                    className={styles.close}
                    onClick={(e) => {
                        e.preventDefault();
                        playButtonSound();
                        this.props.hideDialog();
                    }}
                >
                    {t('Закрыть')}
                </div>
            ) : null;

            overlay = (
                <div key="overlay" className={styles.overlay} />
            );
            dialog = (
                <div key="dialog" className={styles.dialogWrapper}>
                    <div className={classNameDialog}>
                        {close}
                        {dialogContent}
                    </div>
                </div>
            );
        }

        if (modalRoot) {
            return ReactDOM.createPortal(
                this.props.className ? (
                    <div className={this.props.className}>
                        <ReactCSSTransitionGroup
                            key={overlayKey}
                            transitionName={overlayName}
                            transitionEnterTimeout={overlayFadeEnterTimeout}
                            transitionLeaveTimeout={overlayFadeLeaveTimeout}
                        >
                            {overlay}
                        </ReactCSSTransitionGroup>
                        <ReactCSSTransitionGroup
                            key={dialogKey}
                            transitionName={dialogName}
                            transitionEnter={false}
                            transitionLeaveTimeout={dialogFadeLeaveTimeout}
                        >
                            {dialog}
                        </ReactCSSTransitionGroup>
                    </div>
                ) :
                    (
                        <React.Fragment>
                            <ReactCSSTransitionGroup
                                key={overlayKey}
                                transitionName={overlayName}
                                transitionEnterTimeout={overlayFadeEnterTimeout}
                                transitionLeaveTimeout={overlayFadeLeaveTimeout}
                            >
                                {overlay}
                            </ReactCSSTransitionGroup>
                            <ReactCSSTransitionGroup
                                key={dialogKey}
                                transitionName={dialogName}
                                transitionEnter={false}
                                transitionLeaveTimeout={dialogFadeLeaveTimeout}
                            >
                                {dialog}
                            </ReactCSSTransitionGroup>
                        </React.Fragment>
                    ),
                modalRoot
            );
        } else {
            return null;
        }

    }
}

export default Dialog;
