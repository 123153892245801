import * as React from 'react';
import styles from './RankWrapper.scss';

export type RankTypes = 0 | 1 | 2 | 3 | 4;

export interface IRankWrapper {
    children: React.ReactNode;
    level: RankTypes;
}

const RankWrapper = (props: IRankWrapper) => {
    const className = styles[`level-${props.level}`];

    return (
        <div className={className}>{props.children}</div>
    );
};

export default RankWrapper;
