import classNames from 'classnames';
import * as React from 'react';
import styles from './Message.scss';

export type MessageTypes = 'confirmation' | 'error' | 'info'| 'info-round-icon' | 'empty' | 'warning' | 'danger'
    | 'done' | 'none' | 'timer';
export interface IProps {
    children: React.ReactNode;
    type: MessageTypes;
    style: 'fullsize' | 'inline';
}

class Message extends React.PureComponent<IProps> {
    public render() {
        const classNameContainer = classNames(styles.container, styles[this.props.style]);
        const classNameMessage = classNames(styles.message, styles[this.props.type]);

        return (
            <div className={classNameContainer}>
                <span className={classNameMessage}>{this.props.children}</span>
            </div>
        );
    }
}

export default Message;
