import web2clientapi from '@wg/web2clientapi';
import classNames from 'classnames';
import * as React from 'react';
import styles from './ButtonPromo.scss';
import Button from "../Button/Button";

export interface IProps {
    children: React.ReactNode | string;
    isCenter: boolean;
    isDisabled?: boolean;
    isFlat?: boolean;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

class ButtonPromo extends React.PureComponent<IProps> {

    public onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();

        web2clientapi.sounds.playButtonSound();

        if (this.props.onClick) {
            this.props.onClick(event);
        }
    }

    public render() {
        const classNameContainer = classNames(styles.container, {
            [styles.center]: this.props.isCenter,
            [styles.disabled]: this.props.isDisabled,
        });

        return (
            <div
                className={classNameContainer}
            >
                {!!this.props.isFlat ? (
                    <Button isFlat isLarger type="orange" isDisabled={this.props.isDisabled} onClick={this.onClick}>
                        {this.props.children}
                    </Button>
                ) : (
                    <button
                        className={styles.button}
                        onClick={this.onClick}
                    >
                        <div className={styles.inner}>{this.props.children}</div>
                    </button>
                )}
            </div>
        );
    }
}

export default ButtonPromo;
