import * as React from 'react';
import StepCSSTransitionGroup from '../Animations/StepCSSTransitionGroup';
import styles from './Page.scss';

export interface IPageProps {
    startAnimationIndex: number;
    rules: {
        title: string,
        items: Array<{
            title: string,
            image: string | null,
            descr: Array<string>
        }>
    };
}

class Page extends React.PureComponent<IPageProps, any>{
    public render() {
        return (
            <div className={styles.wrapper}>
                <StepCSSTransitionGroup level={this.props.startAnimationIndex}>
                    <h1 className={styles.header}>{this.props.rules.title}</h1>
                </StepCSSTransitionGroup>
                {this.props.rules.items.map((item, i) => {
                    return (
                        <StepCSSTransitionGroup level={this.props.startAnimationIndex + i} key={`Item${i}`}>
                            <div className={styles.item} >
                                <div className={`${styles.image}`}>
                                    <div className={`${styles.icon} ${item.image ? styles[item.image] : ''}`}></div>
                                </div>
                                <div className={styles.content}>
                                    <span className={styles.title}>{`${item.title}`}</span>
                                    <ul className={styles.description}>
                                        {item.descr.map((descrItem, j) => {
                                            return (
                                                <li key={`Row${j}`}>{`${descrItem}`}</li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </StepCSSTransitionGroup>
                    );
                })}
            </div>
        );
    }
}

export default Page;
