import classNames from 'classnames';
import * as React from 'react';
import styles from './Table.scss';

export interface IProps {
    children: React.ReactNode;
    modify: 'check' | 'small' | 'middle' | 'basis' | 'left' | 'right' | 'center' | 'buttons' | 'role';
    isHidden?: boolean;
    isHovering?: boolean;
    className?: string;
    onClick?: () => void;
}

const TableBodyCell = (props: IProps) => {
    const className = classNames(styles.td, styles[props.modify], {
        [styles.hide]: props.isHidden,
        [styles.isHovering]: props.isHovering,
    }, props.className);

    return (
        <div className={className} onClick={props.onClick}>
            <div className={styles.value}>{props.children}</div>
        </div>
    );
};

export default TableBodyCell;
