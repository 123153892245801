import moment from 'moment';
import * as React from 'react';
import { t } from '../../helpers/localization';
import styles from './CountDown.scss';

export interface IProps {
    date: number;
}

export interface IState {
    days: number;
    hours: number;
    min: number;
    sec: number;
}

class Countdown extends React.Component<IProps, IState> {

    private interval: number;

    public state: IState = {
        days: 0,
        hours: 0,
        min: 0,
        sec: 0,
    };

    public UNSAFE_componentWillMount() {
        const date = this.calculateCountdown(this.props.date);
        date ? this.setState(date) : this.stop();
    }

    public componentDidMount() {
        this.interval = setInterval(() => {
            const date = this.calculateCountdown(this.props.date);
            date ? this.setState(date) : this.stop();
        }, 1000);
    }

    public componentWillUnmount() {
        this.stop();
    }

    public calculateCountdown(endDate: number) {
        const now = moment.utc();
        const end = moment.utc(endDate);

        let diff = end.diff(now, 'seconds');

        if (diff <= 0) return false;

        const timeLeft = {
            years: 0,
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
        };

        if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
            timeLeft.years = Math.floor(diff / (365.25 * 86400));
            diff -= timeLeft.years * 365.25 * 86400;
        }

        if (diff >= 86400) { // 24 * 60 * 60
            timeLeft.days = Math.floor(diff / 86400);
            diff -= timeLeft.days * 86400;
        }

        if (diff >= 3600) { // 60 * 60
            timeLeft.hours = Math.floor(diff / 3600);
            diff -= timeLeft.hours * 3600;
        }

        if (diff >= 60) {
            timeLeft.min = Math.floor(diff / 60);
            diff -= timeLeft.min * 60;
        }

        timeLeft.sec = diff;

        return timeLeft;
    }

    public stop() {
        clearInterval(this.interval);
    }

    public addLeadingZeros(value: string | number) {
        value = String(value);
        while (value.length < 2) {
            value = '0' + value;
        }
        return value;
    }

    public getRoundDays = () => {
        const countDown = this.state;
        return Math.round(
          countDown.days + ((countDown.hours + (countDown.min + countDown.sec / 60) / 60) / 24)
        );
    }

    public getDayslabel = () => {
        const daysMap = {
            0: t('0 дней'),
            1: t('1 день'),
            2: t('2 дня'),
            3: t('3 дня'),
            4: t('4 дня'),
            5: t('5 дней'),
            6: t('6 дней'),
            7: t('7 дней'),
        };

        const days = this.getRoundDays();
        let label = daysMap[days] || '';
        if (label) {
            label = label.replace(days, '').trim();
        }
        return label;
    }

    public render() {
        const countDown = this.state;

        if (countDown.days > 0) {
            return (
                <div className={styles.countdown}>
                    <div className={styles.countdownCol}>
                        <span className={`${styles.coundownNumber} ${styles.coundownNumberMargin}`}>{this.getRoundDays()}</span>
                        <span className={styles.daysLabel}>{this.getDayslabel()}</span>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={styles.countdown}>
                    <div className={styles.countdownCol}>
                        <span className={styles.coundownNumber}>{this.addLeadingZeros(countDown.hours)}</span>
                        <span className={styles.coundownNumber}>{':'}</span>
                    </div>

                    <div className={styles.countdownCol}>
                        <span className={styles.coundownNumber}>{this.addLeadingZeros(countDown.min)}</span>
                        <span className={styles.coundownNumber}>{':'}</span>
                    </div>

                    <div className={styles.countdownCol}>
                        <span className={styles.coundownNumber}>{this.addLeadingZeros(countDown.sec)}</span>
                    </div>
                </div>
            );
        }
    }
}

export default Countdown;
