import * as React from 'react';
import styles from './Rank.scss';

export type RankLevels = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30;

export type IProps = {
    level: RankLevels,
};

class Rank extends React.PureComponent<IProps> {

    public render() {
        const className = styles[`rank-${this.props.level}`];

        return (
            <i className={className} />
        );
    }
}

export default Rank;
