import * as React from 'react';
import Tooltip from '../Tooltip/Tooltip';
import TooltipBody from '../Tooltip/TooltipBody';
import { default as TD } from '../Tooltip/TooltipDecorator';
import styles from './Realm.scss';

export interface IRealm {
    realm: string;
    realmMap: {
        [key: string]: {
            shortName: string,
            name: string,
        },
    };
}

class RealmElClass extends React.Component<IRealm, any> {
    public render() {
        return (
            <div className={styles.container}>
                <div>
                    {this.props.realmMap[this.props.realm].shortName}
                </div>
            </div>
        );
    }
}

const RealmEl = TD()(RealmElClass);

const Realm = (props: IRealm) => {
    return props.realmMap[props.realm]
        ? (
            <RealmEl
                realm={props.realm}
                realmMap={props.realmMap}
                tooltipBody={
                    (
                        <Tooltip>
                            <TooltipBody>
                                {props.realmMap[props.realm].name}
                            </TooltipBody>
                        </Tooltip>
                    )
                }
            />
        )
        : (<div className={styles.container}>{props.realm}</div>);
};

export default Realm;
