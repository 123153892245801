import classNames from 'classnames';
import * as React from 'react';
import styles from './Link.scss';

export interface IProps {
    children: React.ReactChild;
    isActionLink: boolean;
    to?: string;
    arrow: 'up' | 'down' | '';
    onClick: () => void;
}

const Link = (props: IProps) => {
    const classNameLink = classNames(styles.link, styles[props.arrow]);
    const classNameActionLink = classNames(styles.link, styles.actionLink, styles[props.arrow]);

    return props.isActionLink
        ? (
            <span
                className={classNameActionLink}
                onClick={props.onClick}
            >
                {props.children}
            </span>
        )
        : (
            <a
                href={props.to}
                className={classNameLink}
                onClick={props.onClick}
            >
                {props.children}
            </a>
        );
};

export default Link;
