import React, { PureComponent } from 'react';
import styles from './ButtonSwitch.scss';

export interface IProps {
    value: boolean;
    onChange: (value: boolean) => void;
    stateTextOn: [string, string];
    stateTextOff: [string, string];
}

export type IState = {};

class ButtonSwitch extends PureComponent<IProps, IState> {

    public getOnText = () => {
        if (this.props.value) {
            return this.props.stateTextOn ? this.props.stateTextOn[1] : 'on';
        } else {
            return this.props.stateTextOn ? this.props.stateTextOn[0] : 'on';
        }
    }

    public getOffText = () => {
        if (this.props.value) {
            return this.props.stateTextOff ? this.props.stateTextOff[0] : 'off';
        } else {
            return this.props.stateTextOff ? this.props.stateTextOff[1] : 'off';
        }
    }

    public turnOn = () => {
        if (!this.props.value && this.props.onChange) {
            this.props.onChange(true);
        }
    }

    public turnOff = () => {
        if (this.props.value && this.props.onChange) {
            this.props.onChange(false);
        }
    }

    public render() {
        return (
            <div className={styles.wrapper}>
                <div className={styles.inner}>
                    <div
                        className={`${styles.activeBgOn} ${!this.props.value ? styles.activeBgOff : ''}`}
                    />
                    <div
                        className={`${styles.button} ${styles.buttonOn} ${this.props.value ? styles.buttonOnActive : ''} `}
                        onClick={this.turnOn}
                    >
                        {this.getOnText()}
                    </div>
                    <div
                        className={`${styles.button} ${styles.buttonOff} ${!this.props.value ? styles.buttonOffActive : ''}`}
                        onClick={this.turnOff}
                    >
                        {this.getOffText()}
                    </div>
                </div>
            </div>
        );
    }
}

export default ButtonSwitch;