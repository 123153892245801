import classNames from 'classnames';
import * as React from 'react';
import { thousands } from '../../helpers/formatting';
import Highlighter from '../Highlighter/Highlighter';
import styles from './ClanResource.scss';

export interface IProps {
    amount?: number;
    isMuted?: boolean;
    isFlat?: boolean;
    isNotEnough?: boolean;
}

class ClanResource extends React.PureComponent<IProps, any> {
    public render() {
        const classClanResource = classNames(
            styles.resource,
            {
                [styles.isMuted]: !!this.props.isMuted,
                [styles.isNotEnough]: !!this.props.isNotEnough,
                [styles.isFlat]: !!this.props.isFlat,
            }
        );

        return (
            <Highlighter
                className={classClanResource}
                value={thousands(this.props.amount)}
            />
        );
    }
}

export default ClanResource;
