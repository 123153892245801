import * as React from 'react';
import StepCSSTransitionGroup from '../Animations/StepCSSTransitionGroup';
import styles from './Dialog.scss';

export interface IProps {
    children: React.ReactNode;
}

const DialogFooter = (props: IProps) => (
    <StepCSSTransitionGroup level={3}>
        <div key="footer" className={styles.footer}>{props.children}</div>
    </StepCSSTransitionGroup>
);

export default DialogFooter;
