import * as React from 'react';
import styles from './LabelStyles.scss';

export interface IProps {
    children: React.ReactNode;
}

const Label = (props: IProps) => (
    <div className={styles.label}>{props.children}</div>
);

export default Label;
