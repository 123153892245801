import * as React from 'react';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import styles from './Processing.scss';

const overlayFadeEnterTimeout = 200;
const overlayFadeLeaveTimeout = 200;

export interface IProps {
    label?: string;
    isFetching?: boolean;
    isInside?: boolean;
    useSpinner?: boolean
}

const Processing = (props: IProps) => {

    const {
        label,
        isFetching,
        isInside,
        useSpinner,
    } = props


    const render = () => {
        if (useSpinner) {
            return (
                <div key="loader" className={styles.processingSpinner}>
                    <div className={styles.processingSpinnerInner}>
                        <div className={styles.processingSpinnerIcon} />
                        <div className={styles.processingSpinnerText}>{label}</div>
                    </div>
                </div>
            )
        } else {
            return (
                <div key="spinner" className={!!isInside ? styles.processingInside : styles.processing}>
                    <div className={styles.spinner} />
                    {!!label ? (
                        <div className={styles.label}>{label}</div>
                    ) : null}
                </div>
            )
        }
    }

    return (
        <ReactCSSTransitionGroup
            transitionName="overlay-fade"
            transitionEnterTimeout={overlayFadeEnterTimeout}
            transitionLeaveTimeout={overlayFadeLeaveTimeout}
        >
            {isFetching ? render() : null}
        </ReactCSSTransitionGroup>
    )
}

export default Processing;
