import * as React from 'react';
import styles from './LineClamp.scss';

export interface IProps {
    children: React.ReactNode;
    lines: number;
}

const LineClamp = (props: IProps) => {
    const style: React.CSSProperties = {
        display: '-webkit-box',
        WebkitLineClamp: props.lines,
        WebkitBoxOrient: 'vertical',
        color: '#eee'
    };

    return (
        <div
            className={styles.base}
            style={style}
        >
            {props.children}
        </div>
    );
};

export default LineClamp;
