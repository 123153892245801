import classNames from 'classnames';
import * as React from 'react';
import { t } from '../../helpers/localization';
import Tooltip from '../Tooltip/Tooltip';
import TooltipBody from '../Tooltip/TooltipBody';
import TooltipDecorator from '../Tooltip/TooltipDecorator';
import styles from './HiddenStatisticsIcon.scss';

export interface IIconProps {
    class: string;
}

export interface IProps {
    isClan?: boolean;
    isOwnAccount?: boolean;
    isLarger?: boolean;
    disableTooltip?: boolean;
}

const Icon: any = (props: IIconProps) => (<i className={props.class} />);
const IconWithTooltip: any = TooltipDecorator()(Icon);

class HiddenStatisticsIcon extends React.PureComponent<IProps> {

    constructor(props: IProps) {
        super(props);
    }

    public renderToolip() {
        if (this.props.disableTooltip) return null;

        let content = null;
        if (this.props.isClan) {
            content = (<TooltipBody>{t('Статистика клана скрыта, так как в клане только один игрок, и он скрыл свою статистику:тултип')}</TooltipBody>);
        } else {
            content = this.props.isOwnAccount ? (
                <TooltipBody>
                    <p>{t('Ваша статистика скрыта:тултип статистика скрыта текущего игрока строка 1')}</p>
                    <p>{t('Количество добытых баррелей нефти остаётся доступным для просмотра командующему и заместителям командующего.:тултип статистика скрыта текущего игрока строка 2')}</p>
                </TooltipBody>
            ) : (
                    <TooltipBody>{t('Статистика скрыта:тултип')}</TooltipBody>
                );
        }
        return (
            <Tooltip>{content}</Tooltip>
        );
    }

    public render() {
        const classNameIcon = classNames(styles.icon, {
            [styles.isLarger]: this.props.isLarger,
        });

        return (
            <IconWithTooltip
                class={classNameIcon}
                tooltipBody={this.renderToolip()}
            />
        );
    }
}

export default HiddenStatisticsIcon;
