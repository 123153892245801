import classNames from 'classnames';
import * as React from 'react';
import styles from './ContextMenu.scss';

export interface IContextMenuItemProps {
    disabled: boolean;
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    name: string;
}

class ContextMenuItem extends React.Component<IContextMenuItemProps, any> {
    public render() {
        const classNameItem = classNames(styles.item, {
            [styles.isDisabled]: this.props.disabled,
        });
        return (
            <div className={classNameItem} onClick={this.props.onClick}>
                {this.props.name}
            </div>
        );
    }
}

export default ContextMenuItem;
