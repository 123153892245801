import web2clientapi from '@wg/web2clientapi';
import classNames from 'classnames';
import * as React from 'react';
import range from '../../helpers/range';
import Spinner from '../Spinner/Spinner';
import styles from './Paginator.scss';

const LEADING_PAGE_RANGE_DISPLAYED = 5;
const TRAILING_PAGE_RANGE_DISPLAYED = 5;
const LEADING_PAGE_RANGE = 4;
const TRAILING_PAGE_RANGE = 4;
const NUM_PAGES_OUTSIDE_RANGE = 1;
const ADJACENT_PAGES = 2;
const ADJACENT_OPPOSITE_RANGE = 3;

const getPaginatorVariables = (page: number, pages: number) => {
    let pageNumbers = [];
    let pagesOutsideLeadingRange: number[] = [];
    let pagesOutsideTrailingRange: number[] = [];

    if (pages <= LEADING_PAGE_RANGE_DISPLAYED + ADJACENT_OPPOSITE_RANGE + 1) {
        pageNumbers = range(1, pages + 1);
    } else if (page <= LEADING_PAGE_RANGE) {
        pageNumbers = range(1, LEADING_PAGE_RANGE_DISPLAYED + 1);
        pagesOutsideLeadingRange = range(-ADJACENT_OPPOSITE_RANGE + 1, 1).map((n) => n + pages);
    } else if (page > pages - TRAILING_PAGE_RANGE) {
        pageNumbers = range(pages - TRAILING_PAGE_RANGE_DISPLAYED + 1, pages + 1);
        pagesOutsideTrailingRange = range(0, ADJACENT_OPPOSITE_RANGE).map((n) => n + 1);
    } else {
        pageNumbers = range(page - ADJACENT_PAGES, page + ADJACENT_PAGES + 1);
        pagesOutsideTrailingRange = range(0, NUM_PAGES_OUTSIDE_RANGE).map((n) => n + 1);
        pagesOutsideLeadingRange = range(0, -NUM_PAGES_OUTSIDE_RANGE, -1).map((n) => n + pages);
    }

    return {
        pagesOutsideTrailingRange: pagesOutsideTrailingRange,
        pageNumbers: pageNumbers,
        pagesOutsideLeadingRange: pagesOutsideLeadingRange,
    };
};

export interface IProps {
    page: number;
    total: number;
    limit: number;
    isFetching?: boolean;
    isDisabled?: boolean;
    onSelectedPage: (page: number) => void;
}

class Paginator extends React.PureComponent<IProps> {

    public getTrailingDots() {
        return (
            <div className={styles.hellip}>&hellip;</div>
        );
    }

    public onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, pageNumber: number | null): void => {
        event.preventDefault();

        if (this.props.isFetching || this.props.isDisabled) {
            return;
        }

        let page: any = pageNumber;
        if (page === undefined) {
            return;
        }

        page = parseInt(page, 10);

        if (page !== this.props.page) {
            web2clientapi.sounds.playButtonSound();
            this.props.onSelectedPage(page);
        }
    }

    public getDisplayPages = (page: number, pagesRange: Array<number>): Array<React.ReactNode> => {
        return (
            pagesRange.map((displayPage: number) => {
                const classNameItem = classNames(styles.item, {
                    [styles.isActive]: displayPage === page,
                });

                return (
                    <div
                        key={displayPage}
                        className={classNameItem}
                        data-page={displayPage}
                        onClick={(e) => { this.onClick(e, displayPage); }}
                    >
                        {displayPage}
                    </div>
                );
            })
        );
    }

    public render() {
        const { page, total, limit } = this.props;
        const pages: number = Math.ceil(total / limit);

        if (pages <= 1) return null;

        if (this.props.isFetching) {
            return (
                <div className={styles.container}>
                    <div className={styles.spinner}>
                        <Spinner />
                    </div>
                </div>
            );
        }

        const {
            pagesOutsideTrailingRange,
            pageNumbers,
            pagesOutsideLeadingRange,
        } = getPaginatorVariables(page, pages);
        const hasPrevious = page > 1;
        const hasNext = page < pages;

        let outsideTrailingDots = null;
        if (pagesOutsideTrailingRange.length > 0) {
            outsideTrailingDots = this.getTrailingDots();
        }

        let outsideLeadingDots = null;
        if (pagesOutsideLeadingRange.length > 0) {
            outsideLeadingDots = this.getTrailingDots();
        }

        const classNamePrevious = classNames(styles.previous, {
            [styles.isDisabled]: !hasPrevious,
        });

        const classNameNext = classNames(styles.next, {
            [styles.isDisabled]: !hasNext,
        });

        return (
            <div className={styles.container}>
                <div className={styles.paginator}>
                    <div
                        className={classNamePrevious}
                        data-page={hasPrevious ? page - 1 : null}
                        onClick={(e) => {
                            const pageNumber = hasPrevious ? page - 1 : null;
                            if (pageNumber !== null) {
                                this.onClick(e, pageNumber);
                            }
                        }}
                    />
                    <div className={styles.list}>
                        {this.getDisplayPages(page, pagesOutsideTrailingRange)}
                        {outsideTrailingDots}
                        {this.getDisplayPages(page, pageNumbers)}
                        {outsideLeadingDots}
                        {this.getDisplayPages(page, pagesOutsideLeadingRange)}
                    </div>
                    <div
                        className={classNameNext}
                        data-page={hasNext ? page + 1 : null}
                        onClick={(e) => {
                            const pageNumber = hasNext ? page + 1 : null;
                            if (pageNumber !== null) {
                                this.onClick(e, pageNumber);
                            }
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default Paginator;
