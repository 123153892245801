import web2clientapi from '@wg/web2clientapi';
import * as React from 'react';
import styles from './Radio.scss';

export interface IRadio {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isChecked?: boolean;
    isDisabled?: boolean;
    name: string;
    children: React.ReactChild;
    value: string | string[] | number;
}

const onChange = (props: IRadio, e: React.ChangeEvent<HTMLInputElement>) => {
    web2clientapi.sounds.playCheckboxSound();

    if (props.onChange) {
        props.onChange(e);
    }
};

const Radio = (props: IRadio) => (
    <label className={styles.label}>
        <input
            className={styles.input}
            type="radio"
            name={props.name}
            value={props.value}
            checked={props.isChecked}
            disabled={props.isDisabled}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(props, e)}
        />
        <span className={styles.radio} />
        <div className={styles.caption}>
            {props.children}
        </div>
    </label>
);

export default Radio;
