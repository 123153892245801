import * as React from 'react';
import Button, {ButtonType} from '../Button/Button'
import styles from './ButtonIcon.scss'

export type ButtonIconType = 'info' | 'clan-info' | 'check' | 'cross' | 'plus'

export interface IProps {
    type: ButtonIconType,
    buttonType?: ButtonType,
    isFlat?: boolean;
    isDisabled?: boolean;
    onClick?: (event: React.MouseEvent) => void;
    tooltipContent?: React.ReactNode;
}

const ButtonIcon = (props: IProps) => {

    const renderIcon = () => {
        switch (props.type) {
            case 'info': {
                return (
                    <div className={styles.infoIcon} />
                )
            }
            case 'clan-info': {
                return (
                    <div className={styles.clanInfoIcon} />
                )
            }
            case 'check': {
                return (
                    <div className={styles.checkIcon} />
                )
            }
            case 'cross': {
                return (
                    <div className={styles.crossIcon} />
                )
            }
            case 'plus': {
                return (
                    <div className={styles.plusIcon} />
                )
            }

            default: {
                return (
                    <div className={styles.icon} />
                )
            }
        }
    }

    return (
        <Button
            isIcon
            isFlat={props.isFlat}
            isDisabled={props.isDisabled}
            tooltipContent={props.tooltipContent}
            type={props.buttonType}
            onClick={props.onClick}
        >
            {renderIcon()}
        </Button>
    )
}

export default ButtonIcon
