import * as React from 'react';
export interface IProps {
    children: React.ReactNode;
}

const Tooltip = (props: IProps) => {
    return (
        <div>
            {props.children}
        </div>
    );
};

export default Tooltip;
