import * as React from 'react';
import styles from './Content.scss';

export interface IProps {
    children: React.ReactNode;
}

const Content = ({ children }: IProps) => (
    <div className={styles.content}>{children}</div>
);

export default Content;
