import * as React from 'react';
import StepCSSTransitionGroup from '../Animations/StepCSSTransitionGroup';
import styles from './Dialog.scss';

export interface IProps {
    children: React.ReactNode;
    noUnderline?: boolean;
}

class DialogHeader extends React.PureComponent<IProps> {
    public render() {
        let noUnderline = false;

        if (this.props.noUnderline !== undefined && this.props.noUnderline !== null && this.props.noUnderline === true) {
            noUnderline = true;
        }

        return (
            <StepCSSTransitionGroup level={1}>
                <div key="header" className={noUnderline ? styles.headerNoUnderline : styles.header}>{this.props.children}</div>
            </StepCSSTransitionGroup>
        );
    }
}

export default DialogHeader;
