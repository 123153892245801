import * as React from 'react';
import styles from './ClanLeagueSignInline.scss';

export interface IProps {
    color: string;
    title: string;
}

class ClanLeagueSignInline extends React.PureComponent<IProps, any> {
    public render() {
        return (
            <div
                className={styles.container}
                style={{ borderColor: this.props.color }}
            >
                {this.props.title}
            </div>
        );
    }
}

export default ClanLeagueSignInline;
