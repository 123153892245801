import React, { Component } from 'react';
const i = 1.618;

export interface IProps {
    stroke: number;
    fromSelector: string;
    toSelector: string;
    fromSide: Side;
    toSide: Side;
    color: string;
}

export type Side = 'top' | 'bottom' | 'left' | 'right';

export interface IPoint {
    x: number;
    y: number;
}

export interface IState {
    startX: number;
    startY: number;
    endX: number;
    endY: number;
    width: number;
    height: number;
    startCurveOffset: IPoint;
    endCurveOffset: IPoint;
    endLineOffset: IPoint;
    arrow: string | undefined;
}

class Arrow extends Component<IProps, IState> {

    public readonly tailOffset: number;

    public readonly pointerSize: number;

    constructor(props: IProps) {
        super(props);

        this.tailOffset = this.props.stroke * i * i;
        this.pointerSize = (this.tailOffset * 2) * i;

        this.state = {
            startX: 0,
            startY: 0,
            endX: 0,
            endY: 0,
            width: 0,
            height: 0,
            startCurveOffset: { x: 0, y: 0 },
            endCurveOffset: { x: 0, y: 0 },
            endLineOffset: { x: 0, y: 0 },
            arrow: undefined,
        };
    }

    public componentDidMount() {
        this._init();

        window.addEventListener('resize', (e) => {
            this._init();
        }, true);

        window.addEventListener('scroll', (e) => {
            this._init();
        }, true);
    }

    public _init = () => {
        const startEl = this._findEl(this.props.fromSelector);
        const endEl = this._findEl(this.props.toSelector);
        if (startEl && endEl) {
            const startPoint = this._getPoint(startEl, this.props.fromSide);
            const endPoint = this._getPoint(endEl, this.props.toSide);
            const startCurveOffset = this._getCurveOffsets(startEl, this.props.fromSide, startPoint, endPoint);
            const endCurveOffset = this._getCurveOffsets(endEl, this.props.toSide, startPoint, endPoint);
            const endLineOffset = this._getLineOffset(this.props.toSide);
            const arrow = this._getArrow(endPoint, this.props.toSide);

            if (startPoint && endPoint) {
                this.setState({
                    startX: startPoint.x,
                    startY: startPoint.y,
                    endX: endPoint.x,
                    endY: endPoint.y,
                    width: endPoint.x - startPoint.x,
                    height: endPoint.y - startPoint.y,
                    startCurveOffset,
                    endCurveOffset,
                    endLineOffset,
                    arrow,
                });
            }
        }
    }


    public _getLineOffset = (side: Side = 'top') => {
        let x = 0;
        let y = 0;

        switch (side) {
            case 'top': {
                y = this.pointerSize;
                return { x, y };
            }
            case 'bottom': {
                y = -this.pointerSize;
                return { x, y };
            }
            case 'left': {
                x = this.pointerSize;
                return { x, y };
            }
            case 'right': {
                x = -this.pointerSize;
                return { x, y };
            }
            default: {
                return { x, y };
            }
        }
    }

    public _getArrow = (endPoint: IPoint, side: Side = 'top') => {
        switch (side) {
            case 'top': {
                return `${endPoint.x},${endPoint.y} ${endPoint.x - this.tailOffset},${endPoint.y - this.pointerSize}  ${endPoint.x + this.tailOffset},${endPoint.y - this.pointerSize}`;
            }
            case 'bottom': {
                return `${endPoint.x},${endPoint.y} ${endPoint.x - this.tailOffset},${endPoint.y + this.pointerSize}  ${endPoint.x + this.tailOffset},${endPoint.y + this.pointerSize}`;
            }
            case 'left': {
                return `${endPoint.x},${endPoint.y} ${endPoint.x - this.pointerSize},${endPoint.y - this.tailOffset} ${endPoint.x - this.pointerSize},${endPoint.y + this.tailOffset}`;
            }
            case 'right': {
                return `${endPoint.x},${endPoint.y} ${endPoint.x + this.pointerSize},${endPoint.y - this.tailOffset} ${endPoint.x + this.pointerSize},${endPoint.y + this.tailOffset}`;
            }
            default: {
                return undefined;
            }
        }
    }

    public _findEl = (selector: string): HTMLElement | null => {
        return document.querySelector(selector);
    }

    public _getPoint = (el: HTMLElement, side: Side = 'top'): IPoint => {
        const rect = el.getBoundingClientRect();

        switch (side) {
            case 'top': {
                const x = rect.left + (rect.width / 2);
                const y = rect.top;// - window.scrollY;
                return { x, y };
            }
            case 'bottom': {
                const x = rect.left + (rect.width / 2);
                const y = rect.top + rect.height;// - window.scrollY;
                return { x, y };
            }
            case 'left': {
                const x = rect.left;
                const y = rect.top + (rect.height / 2);// - window.scrollY;
                return { x, y };
            }
            case 'right': {
                const x = rect.left + rect.width;
                const y = rect.top + (rect.height / 2);// - window.scrollY
                return { x, y };
            }
            default: {
                return {
                    x: 0,
                    y: 0,
                };
            }
        }
    }

    public _getCurveOffsets = (el: Element, side: Side, startPoint: IPoint, endPoint: IPoint) => {
        const S = side ? side : 'top';
        const distance = Math.sqrt(Math.pow((endPoint.x - startPoint.x), 2) + Math.pow((endPoint.y - startPoint.y), 2));
        const curveindex = distance * 0.2;

        switch (S) {
            case 'top': {
                return { x: 0, y: curveindex };
            }
            case 'bottom': {
                return { x: 0, y: -curveindex };
            }
            case 'left': {
                return { x: curveindex, y: 0 };
            }
            case 'right': {
                return { x: -curveindex, y: 0 };
            }
            default: {
                return { x: 0, y: 0 };
            }
        }
    }


    public render() {
        const path = `M${this.state.startX},${this.state.startY}C${this.state.startX - this.state.startCurveOffset.x},${this.state.startY - this.state.startCurveOffset.y} ${this.state.endX - this.state.endCurveOffset.x},${this.state.endY - this.state.endCurveOffset.y} ${this.state.endX - this.state.endLineOffset.x},${this.state.endY - this.state.endLineOffset.y}`;
        return (
            <svg
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    pointerEvents: 'none',
                }}
                width={window.innerWidth}
                height={window.innerHeight}
                viewBox={`0 0 ${window.innerWidth} ${window.innerHeight}`}
                preserveAspectRatio="none"
            >
                <path d={path} stroke={this.props.color ? this.props.color : '#000'} strokeWidth={this.props.stroke} fill="transparent" />
                <polygon points={this.state.arrow} fill={this.props.color ? this.props.color : '#000'} />
            </svg>
        );
    }
}

export default Arrow;
