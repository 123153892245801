import classNames from 'classnames';
import * as React from 'react';
import styles from './Tooltip.scss';

export interface IProps {
    children?: React.ReactNode;
    withMouseClick?: boolean;
    bgColor?: 'dark';
}

const TooltipBody = (props: IProps) => {
    const classNameBody = classNames(
        styles.body,
        props.bgColor && styles[props.bgColor],
        {
            [styles.withMouseClick]: props.withMouseClick,
        }
    );

    return (
        <div className={classNameBody}>{props.children}</div>
    );
};

export default TooltipBody;
