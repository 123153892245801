import classnames from 'classnames'
import React from 'react'
import Alert from 'react-s-alert'
import { NotificationData } from './NotificationManager'
import styles from './NotificationTemplate.scss'

export interface NotificationTemplateProps {
    id: number,
    customFields: NotificationData,
    classNames: string,
    styles: any,
}

const NotificationTemplate = (props: NotificationTemplateProps) => {

    const close = () => {
        Alert.close(props.id)
    }

    const isError = !!props.customFields.isError

    const wrapper = classnames(styles.wrapper, {
        [props.classNames]: !!props.classNames
    })

    const bodyClassname = classnames(styles.body, {
        [styles.errorBody]: isError,
        [styles.taggedBody]: (!props.customFields.header && !isError)
    })

    return (
        <div
            className={wrapper}
            id={`${props.id}`}
            style={props.styles}
            onClick={close}
        >
            {props.customFields.header && (
                <div className={styles.header} dangerouslySetInnerHTML={{ __html: props.customFields.header }} />
            )}
            <div className={bodyClassname}>
                <div className={styles.bodyContent} dangerouslySetInnerHTML={{ __html: props.customFields.message }} />
            </div>
        </div>
    )
}

export default NotificationTemplate
