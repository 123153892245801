import * as React from 'react';
import { toRoman } from '../../helpers/formatting';
import { getClassClanLeverWrapper } from '../../helpers/supply';
import styles from './ClanLevel.scss';


export interface IProps {
    clanLevel: number;
}

export class ClanLevel extends React.PureComponent<IProps, any> {
    public render() {
        const classClanLeverWrapper = getClassClanLeverWrapper(this.props.clanLevel);
        const classNameBase = styles[`clan-level-wrapper-${classClanLeverWrapper}`];

        return (
            <div className={classNameBase}>
                <div className={styles.levelRomain}>
                    {toRoman(this.props.clanLevel)}
                </div>
            </div>
        );
    }
}

export default ClanLevel;
