import React from 'react';
import { default as TD } from "../Tooltip/TooltipDecorator";

export interface IDivTooltipProps {
    onClick?: (event?: React.MouseEvent) => void;
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactNode;
    fixed?: boolean;
    position?: 'top' | 'left' | 'right' | 'bottom' | 'center-right' | 'center-left';
    onMouseEnter?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onMouseLeave?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onMouseMove?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const defaultStyles: {
    [key: string]: string
} = {
    cursor: "initial"
};

const DivTooltip = React.forwardRef(({ className, onClick, position, style, children, onMouseEnter, onMouseLeave, onMouseMove, fixed }: IDivTooltipProps, ref: React.Ref<HTMLDivElement>) => {
    return (
        <div
            ref={ref}
            className={`${className ? ` ${className}` : ''}`}
            style={style ? { ...defaultStyles, ...style } : { ...defaultStyles }}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseMove={onMouseMove}
        >
            {children}
        </div>
    )
})

export default TD()(DivTooltip);