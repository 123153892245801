import classNames from 'classnames';
import * as React from 'react';
import styles from './Tooltip.scss';

export interface IProps {
    children?: React.ReactNode;
    isHelpIcon?: boolean;
    isBold?: boolean;
}

const TooltipHeader = (props: IProps) => {
    const classNameHeader = classNames(styles.header, {
        [styles.isHelpIcon]: props.isHelpIcon,
        [styles.isBold]: props.isBold,
    });

    return (
        <div className={classNameHeader}>
            {props.children}
        </div>
    );
};

export default TooltipHeader;
