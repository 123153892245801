import web2clientapi from '@wg/web2clientapi';
import classNames from 'classnames';
import * as React from 'react';
import { getPositionOnViewport } from '../../helpers/position';
import Tooltip from '../Tooltip/Tooltip';
import TooltipBody from '../Tooltip/TooltipBody';
import TooltipDecorator from '../Tooltip/TooltipDecorator';
import styles from './ContextMenu.scss';
import ContextMenuItem from './ContextMenuItem';
export interface IItem {
    name: string;
    value: string | {};
    disabled: boolean;
    tooltip?: string;
}

export interface IProps {
    context: Array<{
        isNeedDivider: boolean,
        items: Array<IItem>
    }>;
    position: {
        x: number,
        y: number,
    };
    onItemClick: (value: string | {}) => void;
    onContextMenuStateChange: (val: boolean) => void;
    isDemo: boolean;
}

export interface IState {
    isActive: boolean;
    left: number;
    top: number;
    onContextMenuStateChange: (val: boolean) => void;
}

class ContextMenu extends React.PureComponent<IProps, IState> {

    public static defaultProps = {
        onContextMenuStateChange: () => null,
    };

    public _contextMenu: HTMLDivElement;

    public state: IState = {
        isActive: false,
        left: this.props.position.x,
        top: this.props.position.y,
        onContextMenuStateChange: this.props.onContextMenuStateChange,
    };

    public static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
        if (
            prevState.left !== nextProps.position.x ||
            prevState.top !== nextProps.position.y
        ) {
            const isActive = !!(nextProps.position.x && nextProps.position.y);
            prevState.onContextMenuStateChange(isActive);

            return {
                isActive,
                left: nextProps.position.x,
                top: nextProps.position.y,
            };
        }

        return null;
    }

    public componentDidUpdate() {
        const position = getPositionOnViewport(this._contextMenu, {
            top: this.state.top,
            left: this.state.left,
        });
        if (position.left !== this.state.left || position.top !== this.state.top) {
            this.setState({
                left: position.left,
                top: position.top,
            });
        }
        this._contextMenu.focus();
    }

    public componentDidMount() {
        document.addEventListener('keydown', this.onEscape, true);
    }

    public componentWillUnmount() {
        document.removeEventListener('keydown', this.onEscape, true);
    }

    public hide = () => {
        if (this.state.isActive) {
            const isActive = false;
            this.setState({ isActive });
            this.props.onContextMenuStateChange(isActive);
        }
    }

    public onClick = (event: any, item: IItem) => {
        event.stopPropagation();

        if (!item.disabled) {
            this.hide();
            web2clientapi.sounds.playButtonSound();
            this.props.onItemClick(item.value);
        }
    }

    public onEscape = (e: any) => {
        if (e.keyCode === 27 && this.state.isActive) {
            e.preventDefault();
            this.hide();
        }
    }

    public renderDivider(isNeedDivider: boolean) {
        return isNeedDivider ? <div className={styles.divider} /> : null;
    }

    public renderMenuItems(items: Array<IItem>) {
        return items.map((item, index) => {
            if (!item) {
                return null;
            }

            let tooltip = null;

            if (item.tooltip) {
                tooltip = (
                    <Tooltip>
                        <TooltipBody>
                            {item.tooltip}
                        </TooltipBody>
                    </Tooltip>
                );
            }

            const ContextMenuItemWithDecorator = TooltipDecorator()(ContextMenuItem);

            return (
                <ContextMenuItemWithDecorator
                    key={index}
                    onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => { this.onClick(e, item); }}
                    tooltipBody={tooltip}
                    name={item.name}
                    disabled={item.disabled}
                />
            );
        });
    }

    public renderMenu() {
        return this.props.context.map((context, index) => {
            return (
                <div key={index}>
                    {this.renderDivider(context.isNeedDivider)}
                    {this.renderMenuItems(context.items)}
                </div>
            );
        });
    }

    public render() {
        const classNameMenu = classNames(styles.menu, {
            [styles.isDropped]: this.state.isActive,
            [styles.isDemo]: this.props.isDemo,
        });

        const style = {
            top: this.state.top,
            left: this.state.left,
        };

        return (
            <div
                className={classNameMenu}
                tabIndex={1}
                onBlur={this.hide}
                ref={(c) => {
                    if (c) {
                        this._contextMenu = c;
                    }
                }}
                style={style}
            >
                <div className={styles.inner}>{this.renderMenu()}</div>
            </div>
        );
    }
}

export default ContextMenu;
