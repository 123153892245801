import { sendCommand } from '@/core/command';
import { log } from '@/utils/logger';


export function sendAction(params: any) {
  log('Calling action', params);

  return sendCommand({
    command: 'action',
    params,
  });
}
