// @ts-ignore
import web2client from '@wg/web2clientapi'
import React from 'react'
import Alert from 'react-s-alert'
import { CurrencyType } from '../Currency/Currency'
import styles from './NotificationManager.scss'
import NotificationTemplate from './NotificationTemplate'

const MESSAGE_TIMEOUT = 1000 * 4

export interface NotificationData {
    hasHeader?: boolean,
    header?: string,
    message: string,
    cost?: number,
    currency?: CurrencyType,
    isError?: boolean,
}

class NotificationManager extends React.PureComponent {

    styles = styles

    static sendWebNotification = (params: NotificationData) => {
        Alert.info('', {
            customFields: params,
            position: 'bottom-right',
            effect: 'stackslide',
            html: true,
        })
    }

    static sendNotification = (params: NotificationData) => {

        let messageParams: NotificationData = {
            ...params
        }

        if (params.cost) {
            messageParams.currency = messageParams.currency || 'gold'
        }

        if (window.jsHostQuery && typeof window.jsHostQuery === 'function') {
            web2client._sendCommand({
                command: 'notification',
                params: params,
            })
        } else {
            NotificationManager.sendWebNotification(params)
        }


    }

    render() {
        return (
            <Alert
                key={'AlertProvider'}
                html={true}
                stack={{ limit: 5, spacing: 10 }}
                contentTemplate={NotificationTemplate}
                timeout={MESSAGE_TIMEOUT}
                position={'bottom-right'}
                effect={'stackslide'}
                offset={20}
            />
        )
    }
}

export default NotificationManager