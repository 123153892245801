import web2clientapi from '@wg/web2clientapi';
import classNames from 'classnames';
import * as React from 'react';
import styles from './Menu.scss';

export type ItemValue = number;

interface IItem {
    value: ItemValue;
    content: string | React.ReactNode;
}

interface IProps {
    children?: React.ReactChild;
    items: Array<IItem>;
    itemSelected: number;
    dropdownPosition: 'top' | 'bottom' | 'left';
    onItemChange: (value: ItemValue) => void;
    onMenuBlur?: () => void;
    onMenuToggled?: (bool: boolean) => void;
    isSmallPadding?: boolean;
}

interface IState {
    isActive: boolean;
}

class Menu extends React.Component<IProps, IState> {
    public static defaultProps = {
        dropdownPosition: 'bottom',
    };

    public state = {
        isActive: false,
    };

    public appContainer: HTMLElement | null;

    public componentDidMount() {
        this.appContainer = document.getElementById('app');
        if (this.appContainer) {
            this.appContainer.addEventListener('scroll', this.onScroll);
        }
        document.addEventListener('keydown', this.onEscape, true);
    }

    public componentWillUnmount() {
        if (this.appContainer) {
            this.appContainer.removeEventListener('scroll', this.onScroll);
        }
        document.removeEventListener('keydown', this.onEscape, true);
    }

    public hide = (silent: boolean = false) => {
        if (this.state.isActive) {
            this.setState({isActive: false});
            if (!silent) {
                web2clientapi.sounds.playDropdownSound();
            }
        }
    }

    public onToggle = () => {
        const isActive = !this.state.isActive;

        this.setState({isActive});
        if (this.props.onMenuToggled) {
            this.props.onMenuToggled(isActive);
        }

        web2clientapi.sounds.playDropdownSound();
    }

    public onBlur = () => {
        this.hide();
        if (this.props.onMenuBlur) {
            this.props.onMenuBlur();
        }
    }

    public onEscape = (e: KeyboardEvent) => {
        if (e.keyCode === 27 && this.state.isActive) {
            e.preventDefault();
            this.hide();
        }
    }


    public onScroll = () => {
        this.hide();
    }

    public onItemChange = (value: ItemValue) => {
        this.hide(true);
        web2clientapi.sounds.playButtonSound();
        this.props.onItemChange(value);
    }

    public render() {
        const isSmallPadding = this.props.isSmallPadding === true;
        const classNameToggle = classNames(isSmallPadding ? styles.toggleSmall : styles.toggle, {
            [styles.isActive]: this.state.isActive,
        });

        const menuWrapper = classNames(styles.menuWrapper, styles[this.props.dropdownPosition], {
            [styles.isDropped]: this.state.isActive,
        });

        const menuItems = this.props.items.map((item: IItem, index) => {
            const classNameItem = classNames(styles.item, {
                [styles.isSelected]: this.props.itemSelected === item.value,
            });

            return (
                <div
                    className={classNameItem}
                    key={index}
                    onClick={() => {
                        this.onItemChange(item.value);
                    }}
                >
                    {item.content}
                </div>
            );
        });

        return (
            <div className={styles.container} tabIndex={1} onBlur={this.onBlur}>
                <div className={classNameToggle} onClick={this.onToggle}>
                    {this.props.children}
                </div>
                <div className={menuWrapper}>
                    <div className={classNames(styles.menu, {[styles.rotate]: this.props.dropdownPosition === 'top'})}>
                        <div className={styles.inner}>{menuItems}</div>
                    </div>
                    <div className={styles.triangle}/>
                </div>

            </div>
        );
    }
}

export default Menu;
