import classNames from 'classnames';
import * as React from 'react';
import { CSSProperties } from 'react';

import { formatNumber } from '../../helpers/formatting';
import styles from './ResourceImage.scss';

export type ResourceType = 'coal' | 'credits' | 'lootbox' | 'oil' | 'signals' | 'steel' | 'cap-exp';

export interface IResourceImageCustomization {
  image: string;
  className?: string;
  textColor?: string;
  badgeColor?: string;
}

const typeToClass: Record<ResourceType, IResourceImageCustomization> = {
  coal: { image: '', className: styles.coal },
  credits: { image: '', className: styles.credits },
  lootbox: { image: '', className: styles.lootbox },
  oil: { image: '', className: styles.oil },
  signals: { image: '', className: styles.signals },
  steel: { image: '', className: styles.steel },
  'cap-exp': { image: '', className: styles.capExp, textColor: '#98DEDE' },
};

export interface IBaseResourceImageProps {
  styles?: CSSProperties;
  amount?: number;
  single?: boolean;
}

export interface ISampleResourceImageProps extends IBaseResourceImageProps {
  type: ResourceType;
}

export interface ICustomResourceImageProps extends IResourceImageCustomization, IBaseResourceImageProps {}

export type IResourceImageProps = ISampleResourceImageProps | ICustomResourceImageProps;

const isCustom = (props: IResourceImageProps): props is ICustomResourceImageProps =>
  (props as ICustomResourceImageProps).image !== undefined;

const ResourceImage: React.FC<IResourceImageProps> = (props): React.ReactElement => {
  const customization: IResourceImageCustomization = isCustom(props)
    ? { image: props.image, textColor: props.textColor, badgeColor: props.badgeColor }
    : typeToClass[props.type];
  const amount = props?.amount || 0;
  const amountBadge = (
    <div
      className={classNames(styles.amountBadge, {
        [styles.single]: !!props?.single,
        [styles.small]: amount >= 1e6,
        [styles.xlSmall]: amount >= 1e7,
      })}
      style={{ color: customization?.textColor || '#fff', backgroundColor: customization?.badgeColor || '#212222' }}
    >
      {formatNumber(amount)}
    </div>
  );
  const style = { ...props.styles };
  if (customization.image) {
    style['backgroundImage'] = `url(${customization.image})`;
  }

  return (
    <div className={classNames(styles.resourceImage, customization?.className || '')} style={style}>
      {amountBadge}
    </div>
  );
};

export default ResourceImage;
