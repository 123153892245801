import * as React from 'react';
import { t } from '../../helpers/localization';
import Input from '../Input/Input';
import styles from './Autocomplete.scss';

export type IProps = {
    value: string | null,
    width?: string,
    maxLength?: number,
    minLength?: number,
    onChange: (value: string) => void,
    onSearch: (value: string | null) => void,
    onSelect: (item: any) => void,
    onClear: () => void,
    placeholder?: string,
    autocomplete: Array<string>,
    suggestionCount: number,
    renderItem: any,
};

export type IState = {
    isVisible: boolean,
    selectedIndex?: number | null,
};

class Autocomplete extends React.Component<IProps, IState> {

    public state: IState = {
        isVisible: false,
        selectedIndex: null,
    };

    public input: React.RefObject<HTMLInputElement>;

    public blurInput = () => {
        if (this.input && this.input.current) {
            this.input.current.blur();
        }
    }

    public onBlur() {
        this.setState({ isVisible: false, selectedIndex: null });
    }

    public onFocus() {
        this.setState({ isVisible: true });
    }

    public onKeyPress(e: React.KeyboardEvent) {
        switch (e.nativeEvent.keyCode) {
            case 27: {
                this.onEsc();
                break;
            }

            case 13: {
                this.onEnter();
                break;
            }

            case 38: {
                this.onArrowUp();
                break;
            }

            case 40: {
                this.onArrowDown();
                break;
            }

            default: {
                return;
            }
        }
    }

    public onEsc = () => {
        this.onBlur();
        this.blurInput();
    }

    public onEnter = () => {
        this.onBlur();
        this.blurInput();

        if (this.state.selectedIndex !== null && this.state.selectedIndex !== undefined) {
            this.onSelect(this.state.selectedIndex);
        } else {
            this.props.onSearch(this.props.value);
        }

    }

    public onArrowDown = () => {
        const count = this.props.suggestionCount ? this.props.suggestionCount : 5;
        let newIndex = 0;

        if (this.state.selectedIndex) {
            newIndex = this.state.selectedIndex + 1;
        }

        if (this.state.selectedIndex === null) {
            newIndex = 0;
        }

        if (newIndex === count) {
            newIndex = 0;
        }

        this.setState({ selectedIndex: newIndex });
    }

    public onArrowUp = () => {
        const count = this.props.suggestionCount ? this.props.suggestionCount : 5;
        let newIndex = 0;

        if (this.state.selectedIndex) {
            newIndex = this.state.selectedIndex - 1;
        }

        if (newIndex < 0) {
            newIndex = count - 1;
        }

        this.setState({ selectedIndex: newIndex });
    }

    public onSelect(index: number){
        if (this.props.autocomplete && this.props.autocomplete.length && this.props.autocomplete[index] && this.props.onSelect) {
            this.props.onSelect(this.props.autocomplete[index]);
        }
    }

    public onTextInput (e: React.ChangeEvent<HTMLInputElement>) {
        this.props.onChange(e.target.value);
        if (e.target.value.length === 0) {
            this.props.onClear();
        }
    }

    public onItemClick(index: number) {
        this.onBlur();
        this.blurInput();
        this.onSelect(index);
    }

    public overlayClick() {
        this.onBlur();
        this.blurInput();
    }

    public renderAutocomplete() {
        const autocompleteItems = (this.props.autocomplete && this.props.autocomplete.length > 0) ? this.props.autocomplete.slice(0, this.props.suggestionCount ? this.props.suggestionCount : 5) : [];
        const minLength = this.props.minLength || 2;

        if (this.state.isVisible && this.props.value && this.props.value.length < minLength && this.props.value.length > 0) {
            return (
                <div className={styles.autocomplete}>
                    <div className={styles.overlay} onClick={this.overlayClick} />
                    <div className={styles.message}>{t('Введите хотя бы 2 символа для поиска клана.')}</div>
                </div>
            );
        } else if (this.state.isVisible && autocompleteItems && autocompleteItems.length > 0) {
            return (
                <div className={styles.autocomplete}>
                    <div className={styles.overlay} onClick={this.overlayClick} />
                    {autocompleteItems.map((item, index) => {
                        const isSelected = index === this.state.selectedIndex;
                        return (
                            <div key={`autocomplete-item-${index}`} className={`${styles.item} ${isSelected ? styles.selected : ''}`} onClick={this.onItemClick.bind(this, index)}>
                                {this.props.renderItem ? this.props.renderItem(item) : `${item}`}
                            </div>
                        );
                    })}
                </div>
            );
        }

        return null;
    }

    public clear() {
        this.onBlur();
        this.blurInput();
        if (this.props.onClear) {
            this.props.onClear();
        }
    }

    public render() {
        const isEnterVisible = this.props.value && this.props.value.length > 1;
        return (
            <div className={styles.wrapper}>
                <Input
                    ref={(r) => {
                        if (r) {
                            this.input = r._input;
                        }
                    }}
                    noimesupport
                    value={this.props.value || ""}
                    width={this.props.width}
                    maxLength={this.props.maxLength}
                    onChange={this.onTextInput.bind(this)}
                    placeholder={this.props.placeholder}
                    onFocus={this.onFocus.bind(this)}
                    onKeyDownCapture={this.onKeyPress.bind(this)}
                    isFocusOn={this.state.isVisible}
                    onBlur={this.onBlur.bind(this)}
                    t={t}
                />
                {this.renderAutocomplete()}

                {isEnterVisible ? (
                    <div className={styles.enter} onClick={this.onEnter} />
                ) : null}
                {isEnterVisible ? (
                    <div className={styles.clear} onClick={this.clear.bind(this)} />
                ) : null}
            </div>
        );
    }
}

export default Autocomplete;