import * as React from "react";
import { tooltipUniqueId } from './TooltipWrapper';
export interface IExternalProps {
  tooltipBody?: any;
  delay?: number;
  fixed?: boolean;
  position?: string;
  className?: any;
  isDisabled?: boolean;
}

export type State = any;

let tooltipGap = 20;

const TooltipDecoratorClass = () => <T extends {}>(Component: (React.ComponentClass<T> | React.StatelessComponent<T>)) => {

  type ResultProps = T & IExternalProps;
  const result = class TooltipDecorator extends React.Component<ResultProps, State> {
    public static displayName = `TooltipDecorator(${Component.displayName || Component.name})`;

    public static defaultProps = {
      isDisabled: false,
    };

    public wrapper: HTMLDivElement;

    public componentWillUnmount() {
      if (window.tooltipProvider) {
        window.tooltipProvider.hide();
      }
    }

    public onMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (this.props.tooltipBody !== null && this.props.tooltipBody !== undefined && !this.props.isDisabled) {
        if (window.tooltipProvider) {
          const delay = this.props.delay ? this.props.delay : 0;
          setTimeout(() => {
            if (window.tooltipProvider) {
              window.tooltipProvider.show(this.props.tooltipBody);
              if (this.props.fixed) {
                const wrapperBox = this.wrapper.getBoundingClientRect();
                window.tooltipProvider.update({ x: wrapperBox.x - wrapperBox.width / 2, y: wrapperBox.y - wrapperBox.height / 2 });
              }
            }
          }, delay);
        }
      }
    }

    public onMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (window.tooltipProvider) {
        window.tooltipProvider.hide();
      }
    }

    public onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      let cursorX = e.pageX - window.scrollX;
      let cursorY = e.pageY - window.scrollY + tooltipGap;
      const isDisabledTooltip = !this.props.tooltipBody || this.props.isDisabled;
      if (isDisabledTooltip) {
          return;
      }

      const tooltipBody = document.getElementById(tooltipUniqueId);
      if (this.props.fixed && this.wrapper && tooltipBody) {
        tooltipGap = 10;
        const wrapperBox = this.wrapper.getBoundingClientRect();
        const bodyBox = tooltipBody.getBoundingClientRect();
        switch (this.props.position) {
          case 'top': {
            cursorX = wrapperBox.x + wrapperBox.width / 2 - bodyBox.width / 2;
            cursorY = wrapperBox.y - bodyBox.height - tooltipGap;
            break;
          }
          case 'right': {
            cursorX = wrapperBox.x + wrapperBox.width + tooltipGap;
            cursorY = wrapperBox.y;
            break;
          }
          case 'center-right': {
            cursorX = wrapperBox.x + wrapperBox.width + tooltipGap;
            cursorY = wrapperBox.y + (wrapperBox.height / 2) - (bodyBox.height / 2);
            break;
          }
          case 'bottom': {
            cursorX = wrapperBox.x + wrapperBox.width / 2 - bodyBox.width / 2;
            cursorY = wrapperBox.y + bodyBox.height + tooltipGap;
            break;
          }
          case 'left': {
            cursorX = wrapperBox.x - bodyBox.width - tooltipGap;
            cursorY = wrapperBox.y;
            break;
          }
          case 'center-left': {
            cursorX = wrapperBox.x - bodyBox.width - tooltipGap;
            cursorY = wrapperBox.y + (wrapperBox.height / 2) - (bodyBox.height / 2);
            break;
          }
          default: {
            break;
          }
        }
      }

      if (cursorX < tooltipGap) {
        cursorX = tooltipGap
      }

      if (cursorY < tooltipGap) {
        cursorY = tooltipGap
      }

      if (window.tooltipProvider) {
        window.tooltipProvider.setBodyContentIfNeeded(this.props.tooltipBody);
        window.tooltipProvider.update({ x: cursorX, y: cursorY });
      }
    }

    public render() {
      return (
        <Component
          {...this.props}
          ref={(r: HTMLDivElement) => {
            if (r) {
              this.wrapper = r;
            }
          }}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          onMouseMove={this.onMouseMove}
        />
      );
    }
  };

  return result;
};

export default TooltipDecoratorClass;