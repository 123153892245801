import * as React from 'react';
import Tooltip from '../Tooltip/Tooltip';
import TooltipBody from '../Tooltip/TooltipBody';
import TooltipHeader from '../Tooltip/TooltipHeader';
import styles from './HelperIcon.scss';
import Icon from './Icon';
import DivTooltip from "../DivTooltip/DivTooltip";

export interface IProps {
  header: string;
  children: React.ReactNode;
  isStatic?: boolean;
  position?: string,
  fixed?: boolean
}

class HelperIcon extends React.PureComponent<IProps> {

  public render() {
    return (
      <div className={styles.container}>
        <DivTooltip
          tooltipBody={
            <Tooltip>
              <TooltipHeader isBold={false} isHelpIcon>{this.props.header}</TooltipHeader>
              <TooltipBody>{this.props.children}</TooltipBody>
            </Tooltip>
          }>
          <Icon />
        </DivTooltip>
      </div>
    );
  }
}

export default HelperIcon;
