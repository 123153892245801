import * as React from 'react';
import Divider from '../Divider/Divider'
import classnames from 'classnames'
import styles from './Popover.scss'

export type PopoverPosition = 'top' | 'right' | 'bottom' | 'left'

export interface ResizeData {
    width: number,
    height: number
}

export interface Popover_Props {
    className?: string,
    position: PopoverPosition,
    isVisible: boolean,
    header?: React.ReactNode,
    content?: React.ReactNode,

    onClose: () => void,
}

export interface Popover_State {
    size?: DOMRect,
    isOpaque: boolean,
}

export default class Popover extends React.Component<Popover_Props, Popover_State> {

    private popover: HTMLDivElement | null;
    private spacer: number = 20
    private interval: number;

    state: Popover_State = {
        size: undefined,
        isOpaque: false
    }

    setPopoverRef = (r: HTMLDivElement) => {
        this.popover = r
        this.updateSize()
    }

    public componentDidMount() {
        this.interval = setInterval(this.updateSize, 50);
    }

    updateSize = () => {
        if (this.popover) {
            const rect = this.popover.getBoundingClientRect();
            if (!this.state.size || (this.state.size.width !== rect.width || this.state.size.height !== rect.height)) {
                this.setState({
                    size: rect
                }, () => {
                    setTimeout(() => {
                        if (this.state.size?.width === rect.width && this.state.size?.height === rect.height && !this.state.isOpaque) {
                            this.setState({
                                isOpaque: true
                            })
                        }
                    }, 80);
                })
            }
        }
    }

    public componentWillUnmount() {
        clearInterval(this.interval);
    }

    getPopoverStyle = () => {
        if (!this.state.size) {
            return undefined
        }
        switch (this.props.position) {
            case 'top': {
                return {
                    top: `-${this.state.size.height + this.spacer}px`,
                    left: '50%',
                    marginLeft: `-${this.state.size.width / 2}px`,
                }
            }
            case 'right': {
                return {
                    top: '50%',
                    right: `-${this.state.size.width + this.spacer}px`,
                    marginTop: `-${this.state.size.height / 2}px`,
                }
            }
            case 'bottom': {
                return {
                    bottom: `-${this.state.size.height + this.spacer}px`,
                    left: '50%',
                    marginLeft: `-${this.state.size.width / 2}px`,
                }
            }
            case 'left': {
                return {
                    top: '50%',
                    left: `-${this.state.size.width + this.spacer}px`,
                    marginTop: `-${this.state.size.height / 2}px`,
                }
            }
            default: {
                return undefined
            }
        }
    }

    getBackground = () => {
        const o = 20
        let w = this.state.size ? this.state.size.width : 0
        let h = this.state.size ? this.state.size.height : 0
        if (this.props.position === 'top' || this.props.position === 'bottom') {
            h += o
        } else {
            w += o
        }
        const wc = w / 2
        const hc = h / 2
        const sw = 2
        const swh = sw / 2
        let points = ''
        switch (this.props.position) {
            case 'top': {
                points = `${swh},${swh} ${w - swh},${swh} ${w - swh},${h - o - swh} ${wc + o},${h - o - swh} ${wc},${h - swh} ${wc - o},${h - o - swh} ${swh},${h - o - swh}`
                break;
            }
            case 'right': {
                points = `${swh + o},${swh} ${w - swh},${swh} ${w - swh},${h - swh} ${swh + o},${h - swh} ${swh + o},${hc + o} ${swh},${hc} ${swh + o},${hc - o}`
                break;
            }
            case 'bottom': {
                points = `${swh},${swh + o} ${wc - o},${swh + o} ${wc},${swh} ${wc + o},${swh + o} ${w - swh},${swh + o} ${w - swh},${h - swh} ${swh},${h - swh}`
                break;
            }
            case 'left': {
                points = `${swh},${swh} ${w - swh - o},${swh} ${w - swh - o},${hc - o} ${w - swh},${hc} ${w - swh - o},${hc + o}  ${w - swh - o},${h - swh} ${swh},${h - swh}`
                break;
            }
            default: {
                break;
            }
        }
        return (
            <div className={styles[`background-${this.props.position}`]}>
                <svg width={'100%'} height={'100%'} viewBox={`0 0 ${w} ${h}`}>
                    <polygon
                        fill={"rgba(0,0,0,0.5)"}
                        stroke={"rgba(255,204,102,0.5)"}
                        strokeWidth={sw}
                        points={points} />
                </svg>
            </div>
        )
    }

    render() {
        const wrapper = classnames(styles.wrapper, {
            [`${this.props.className}`]: !!this.props.className
        })
        const popover = classnames(styles.popover, {
            [styles.invisible]: !this.state.isOpaque
        })

        return (
            <div className={wrapper}>
                {this.props.children}
                {this.props.isVisible ? (
                    <div className={popover} style={this.getPopoverStyle()} ref={this.setPopoverRef}>
                        <div className={styles.inner}>
                            {this.getBackground()}
                            {this.props.header ? (
                                <React.Fragment>
                                    <div className={styles.header}>
                                        {this.props.header}
                                        <div className={styles.close} onClick={this.props.onClose} />
                                    </div>
                                    <Divider type={'major'} />
                                </React.Fragment>
                            ) : null}
                            {this.props.content ? (
                                <div className={styles.content}>
                                    {this.props.content}
                                </div>
                            ) : null}
                        </div>
                    </div>
                ) : null}
            </div>
        )
    }
}
