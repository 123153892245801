import * as React from 'react';
import Highlight from 'react-highlighter';
import ClanTag from '../ClanTag/ClanTag';
import styles from './ClanName.scss';

export interface IProps {
    clanTag: string;
    clanColor: string;
    clanName: string;
    highlight?: string;
    teamName?: string;
    tooltipBody?: any;
    tooltipId?: any;
    seasonType?: string;
    seasonTypes?: any;
    isBrawl?: boolean;
}

class ClanName extends React.PureComponent<IProps> {

    public renderTeamNumber() {
        if (this.props.teamName && !this.props.isBrawl) {
            return <span className={styles.teamName}>{this.props.teamName}&nbsp;</span>;
        }

        return '';
    }

    public render() {
        const MAX_LENGTH = 40
        const name = this.props.clanName.length < MAX_LENGTH ? this.props.clanName : `${this.props.clanName.slice(0, MAX_LENGTH)}...`

        const clanName = this.props.highlight ? (
            <Highlight
                search={this.props.highlight}
                matchElement="span"
            >
                {name}
            </Highlight>
        ) : name;

        return (
            <div className={styles.container}>
                {this.renderTeamNumber()}
                <ClanTag
                    clanTag={this.props.clanTag}
                    clanColor={this.props.clanColor}
                    highlight={this.props.highlight}
                />
                &nbsp;
                <span className={styles.clanName}>{clanName}</span>
            </div>
        );
    }
}

export default ClanName;
