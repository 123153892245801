import web2clientapi from '@wg/web2clientapi';
import classNames from 'classnames';
import * as React from 'react';
import styles from './Tabs.scss';

export type Tab = {
    value: string,
    content: string | React.ReactChild,
};

export type IProps = {
    isUppercase?: boolean,
    smallHeight?: boolean,
    tabs: Array<Tab>,
    tabSelected: string,
    onToggleTab: (tab: string) => void
};

class Tabs extends React.Component<IProps> {

    public toggleTab(tab: string): void {
        if (tab === this.props.tabSelected) {
            return;
        }

        this.props.onToggleTab(tab);
        web2clientapi.sounds.playButtonSound();
    }

    public render() {
        const tabs = this.props.tabs.map((tab, index) => {
            const classNameTab = classNames(styles.tab, {
                [styles.isSelected]: this.props.tabSelected === tab.value,
                [styles.isUppercase]: this.props.isUppercase,
                [styles.smallHeight]: this.props.smallHeight,
            });

            return (
                <div
                    className={classNameTab}
                    key={index}
                    onClick={() => this.toggleTab(tab.value)}
                >
                    <div className={styles.tabContent}>
                        {tab.content}
                    </div>

                </div>
            );
        });

        return (<div className={styles.container}>{tabs}</div>);
    }
}

export default Tabs;
