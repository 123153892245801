import classNames from 'classnames';
import * as React from 'react';
import StickyContainer from '../Sticky/StickyContainer';
import styles from './Table.scss';

export interface IProps {
    children: React.ReactNode;
    stickyContainerId?: string;
    isErrored?: boolean;
    isFetching?: boolean;
    className?: string;
}

const Table = (props: IProps) => {

    const classNameTable = classNames(styles.table, {
        [styles.isErrored]: props.isErrored,
        [styles.isFetching]: props.isFetching,
    }, props.className);

    return props.stickyContainerId
        ? (
            <StickyContainer id={props.stickyContainerId}>
                <div className={classNameTable}>{props.children}</div>
            </StickyContainer>
        )
        : (<div className={classNameTable}>{props.children}</div>);
};

export default Table;
