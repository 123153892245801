import * as React from 'react';
import { t } from '../../helpers/localization';
import Button from '../Button/Button';
import Spinner from '../Spinner/Spinner';
import styles from './ErrorLoad.scss';

export interface IProps {
    message: string;
    buttonTitle?: string;
    isFetching?: boolean;
    isFlat?: boolean;
    onReloadClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ErrorLoad = (props: IProps) => {
    let reloadButton = null;
    if (props.onReloadClick) {
        reloadButton = props.isFetching
            ? <Spinner label={t('Загружаем информацию')} />
            : <Button
                isFlat={props.isFlat}
                isSmaller={props.isFlat}
                onClick={props.onReloadClick}
              >
                {props.buttonTitle ? props.buttonTitle : t('Обновить')}
              </Button>
    }
    return (
        <div className={styles.container}>
            <div>
                <span className={styles.message}>{props.message}</span>
            </div>
            {reloadButton}
        </div>
    );
};

export default ErrorLoad;
