import * as React from 'react';
import classNames from "classnames";
import styles from './Table.scss';


export interface IProps {
    children: React.ReactNode;
    className?: string;
}

const TableBody = (props: IProps) => {
    const className = classNames(styles.tbody, props.className);
    return (
        <div className={className}>{props.children}</div>
    );
};

export default TableBody;
