const nativeCeil = Math.ceil;
const nativeMax = Math.max;

export const baseRange = (start: number, end: number, step: number, fromRight: boolean) => {
    let index = -1;
    let length = nativeMax(nativeCeil((end - start) / (step || 1)), 0);
    const result = Array(length);

    while (length--) {
        result[fromRight ? length : ++index] = start;
        start += step;
    }
    return result;
};

export const range = (start: number, end: number, step: number = 1, fromRight: boolean = false) => {
    return baseRange(start, end, step, fromRight);
};

export default range;