import * as React from 'react';
import styles from './Icon.scss';

export type IconType = 'clan-info' |'info' |'status-accepted' |'status-active' |'status-declined' |'status-expired' |'go-to' |'discount' |'badge-new' |'leading-team';
export interface IProps {
    glyph: IconType;
}

const Icon = (props: IProps) => (
    <i className={styles[props.glyph]}/>
);

export default Icon;
