import { isIngame } from '@/utils/checkIngame';
import { error } from '@/utils/logger';


export function sendCommand(payload: any) {
  if (typeof window.jsHostQuery !== 'function') {
    if (isIngame()) {
      error('window.jsHostQuery does not exist', payload);
    }
    return false;
  }

  const request = (payload && typeof payload === 'object') ? JSON.stringify(payload) : payload;

  return new Promise<void>((resolve, reject) => {
    window.jsHostQuery({
      request,
      persistent: false,
      onSuccess: () => {
        resolve();
      },
      onFailure: () => {
        reject();
      },
    })
  });
}
