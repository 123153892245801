import * as React from 'react';

export interface IProps {
    id: string;
    children: React.ReactNode;
}

const StickyContainer = (props: IProps) => (
    <div id={props.id}>{props.children}</div>
);

export default StickyContainer;
