export const getClassClanLeverWrapper = (clanLevel: number) => {
    let classClanLevelWrapper = null;

    if (clanLevel > 11) {
        classClanLevelWrapper = 3;
    } else if (clanLevel > 8) {
        classClanLevelWrapper = 2;
    } else if (clanLevel > 4) {
        classClanLevelWrapper = 1;
    } else if (clanLevel > 0) {
        classClanLevelWrapper = 0;
    }

    return classClanLevelWrapper;
};
