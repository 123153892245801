import * as React from 'react';
import { L1, L2, L3, L4, R1, R2, R3, R4 } from './parts';
import styles from './Wreath.scss';

export interface Props {
    color?: string,
    isActive?: boolean,

    onClick?: () => void
}

export default class Wreath extends React.Component<Props> {

    render() {
        let color = '#fff'
        if (this.props.color) {
            color = this.props.color
        }

        return (
            <div className={styles.wrapper} onClick={this.props.onClick}>
                <R1 color={color} className={`${styles.part} ${!!this.props.isActive ? '' : styles.R1}`} />
                <R2 color={color} className={`${styles.part} ${!!this.props.isActive ? '' : styles.R2}`} />
                <R3 color={color} className={`${styles.part} ${!!this.props.isActive ? '' : styles.R3}`} />
                <R4 color={color} className={`${styles.part} ${!!this.props.isActive ? '' : styles.R4}`} />
                <L1 color={color} className={`${styles.part} ${!!this.props.isActive ? '' : styles.L1}`} />
                <L2 color={color} className={`${styles.part} ${!!this.props.isActive ? '' : styles.L2}`} />
                <L3 color={color} className={`${styles.part} ${!!this.props.isActive ? '' : styles.L3}`} />
                <L4 color={color} className={`${styles.part} ${!!this.props.isActive ? '' : styles.L4}`} />
            </div>
        )
    }
}
