import * as React from 'react';
import styles from './Divider.scss';

export interface IProps {
    type: 'major' | 'minor' | 'dashed';
}

const Divider = (props: IProps) => (<div className={styles[props.type]} />);

export default Divider;
