import * as React from 'react';
import styles from './Dialog.scss';

export interface IProps {
    children: React.ReactNode;
}

const DialogContainer = (props: IProps) => (
    <div className={styles.container}>{props.children}</div>
);

export default DialogContainer;
