import * as React from 'react';
import styles from './HelperIcon.scss';

class Icon extends React.Component<any, any> {
    public render() {
        return (
            <i className={styles.Icon} />
        );
    }
}

export default Icon;
