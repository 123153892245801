import React, { PureComponent } from 'react';
import isEqual from 'react-fast-compare';
import styles from './Highlighter.scss';

interface IExternalProps {
    i18nKey?: string;
}

interface IProps {
    value: {
        props?: IExternalProps,
    };
    className?: string;
}

interface IState {
    isHighlighted?: boolean;
}

class Highlighter extends PureComponent<IProps> {

    public state: IState;

    constructor(props: IProps) {
        super(props);
        this.state = {
            isHighlighted: false,
        };
    }

    public isInterpolate = (props: IProps, nextProps: IProps) => {
        let r = false;

        if (
            nextProps.value &&
            nextProps.value.props &&
            nextProps.value.props.i18nKey &&
            props.value &&
            props.value.props &&
            props.value.props.i18nKey
        ) {
            r = true;
        }


        return r;
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IProps) {

        const isInterpolate = this.isInterpolate(this.props, nextProps);

        if (isInterpolate && !isEqual(this.props.value.props, nextProps.value.props)) {
            this.setState({
                isHighlighted: true,
            }, this.dropHighLight);
        }

        if (this.props.value !== nextProps.value) {
            this.setState({
                isHighlighted: true,
            }, this.dropHighLight);
        }
    }

    public dropHighLight = () => {
        setTimeout(() => {
            this.setState({
                isHighlighted: false,
            });
        }, 450);
    }

    public render() {
        const value = (this.props.value !== undefined && this.props.value !== null) ? this.props.value : '';
        return (
            <span className={`${styles.wrapper} ${this.props.className ? this.props.className : ''} ${this.state.isHighlighted ? styles.wrapperHighlight : ''}`}>{value}</span>
        );
    }
}

export default Highlighter;
