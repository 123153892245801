import * as React from 'react';
import Highlight from 'react-highlighter';
import styles from './ClanTag.scss';

export interface IProps {
    clanTag: string;
    clanColor: string;
    highlight?: string;
}

class ClanTag extends React.PureComponent<IProps, any> {
    public render() {
        const clanTag = this.props.highlight
            ? (
                <Highlight
                    search={this.props.highlight}
                    matchElement="span"
                >
                    {this.props.clanTag}
                </Highlight>
            )
            : this.props.clanTag;

        return (
            <span className={styles.tag} style={{ color: this.props.clanColor }}>[{clanTag}]</span>
        );
    }
}

export default ClanTag;
