import classNames from 'classnames';
import * as React from 'react';
import StepCSSTransitionGroup from '../Animations/StepCSSTransitionGroup';
import styles from './Dialog.scss';


const CONTENT_PADDING = 20;

export interface IProps {
    children: React.ReactNode;
    id?: string;
    className?: string;
}

export interface IState {
    showShadowTop: boolean;
    showShadowBottom: boolean;
}

class DialogBody extends React.Component<IProps, IState> {

    public _scrollContainer: HTMLDivElement;

    constructor(props: IProps) {
        super(props);

        this.state = {
            showShadowTop: false,
            showShadowBottom: false,
        };
    }

    public onScroll = (e: any) => { // TODO set event type
        const scrollPosition = e.currentTarget.scrollTop;
        const scrollHeight = e.currentTarget.scrollHeight - e.currentTarget.clientHeight - CONTENT_PADDING;

        this.setState({
            showShadowTop: scrollPosition >= CONTENT_PADDING,
            showShadowBottom: scrollPosition <= scrollHeight,
        });
    }

    public componentDidMount() {
        const containerHasScroll = this._scrollContainer ? (this._scrollContainer.scrollHeight > this._scrollContainer.clientHeight) : false;
        this.setState({ showShadowBottom: containerHasScroll });
        this._scrollContainer.addEventListener('scroll', this.onScroll);
    }

    public componentWillUnmount() {
        this._scrollContainer.removeEventListener('scroll', this.onScroll);
    }

    public render() {
        const classNameBody = classNames(styles.body, {
            [styles.showShadowTop]: this.state.showShadowTop,
            [styles.showShadowBottom]: this.state.showShadowBottom,
        });

        return (
            <StepCSSTransitionGroup
                level={2}
                id={this.props.id}
                className={classNameBody}
            >
                <div
                    key="content"
                    id={this.props.id ? `${this.props.id}-content` : undefined}
                    ref={(c) => {
                        if (c) {
                            this._scrollContainer = c;
                        }
                    }}
                    className={`${styles.content} ${this.props.className ? this.props.className : ''}`}
                >
                    {this.props.children}
                </div>
            </StepCSSTransitionGroup>
        );
    }
}

export default DialogBody;
