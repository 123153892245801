import React, { PureComponent } from 'react';
import DivTooltip from '../DivTooltip/DivTooltip';
import TooltipBody from '../Tooltip/TooltipBody';
import styles from './SearchInput.scss';

export interface IProps {
    value: string;
    placeholder?: string;
    autoFocus?: boolean;
    closeTooltip?: string;
    isFullwidth?: boolean;
    onChange: (value: string) => void;
    onClose: () => void;
}

class SearchInput extends PureComponent<IProps> {

    public onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const trimmedValue = event.target.value.trim();
        this.props.onChange(trimmedValue);
    }


    public onClearClick = (event: React.MouseEvent<EventTarget>) => {
        event.stopPropagation();
        this.props.onClose();
        this.props.onChange('');
    }

    public onKeyPress = (e: React.KeyboardEvent) => {
        if (e.keyCode === 27) {
            this.props.onClose();
            this.props.onChange('');
        }
    }

    public onClcik = (event: React.MouseEvent<EventTarget>) => {
        event.stopPropagation();
    }

    public render() {
        return (
            <div className={(this.props.isFullwidth === true) ? styles.containerFullWidth : styles.container} onClick={this.onClcik}>
                <input
                    className={styles.input}
                    maxLength={100}
                    placeholder={this.props.placeholder}
                    spellCheck={false}
                    type="text"
                    value={this.props.value}
                    onChange={this.onChange}
                    autoFocus={this.props.autoFocus}
                    onKeyDownCapture={this.onKeyPress}
                />
                {(this.props.closeTooltip && this.props.closeTooltip.length) ? (
                    <DivTooltip
                        tooltipBody={(
                            <TooltipBody>
                                {this.props.closeTooltip}
                            </TooltipBody>
                        )}
                    >
                        <div className={styles.clear} onClick={this.onClearClick} />
                    </DivTooltip>
                ) : (
                        <div className={styles.clear} onClick={this.onClearClick} />
                    )}

            </div>
        );
    }
}

export default SearchInput;
