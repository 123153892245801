import * as React from 'react';
import styles from './MessageWrap.scss';

export interface IProps {
    title: string;
    message: any;
}

class MessageWrap extends React.PureComponent<IProps> {

    public renderMessage() {
        if (this.props.message !== null && this.props.message !== undefined) {
            return (
                <div className={styles.message}>{this.props.message}</div>
            );
        } else {
            return null;
        }
    }

    public render() {
        return (
            <div className={styles.container}>
                <div className={styles.title}>{this.props.title}</div>
                {this.renderMessage()}
            </div>
        );
    }
}

export default MessageWrap;
