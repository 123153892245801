import classNames from 'classnames';
import * as React from 'react';
import ClanRole from '../ClanRole/ClanRole';
import styles from './MemberName.scss';

export interface IProps {
    role?: string;
    name: string;
    isInline?: boolean;
}

const MemberName = (props: IProps) => {
    const classNameMember = classNames(styles.member, {
        [styles.isInline]: props.isInline,
    });

    const role = props.role
        ? (
            <ClanRole
                key="member_name_role"
                role={props.role}
            />
        )
        : null;

    return (
        <span className={classNameMember}>
            {role}
            <span
                key="member_name"
                className={styles.name}
            >
                {props.name}
            </span>
        </span>
    );
};

MemberName.defaultProps = {
    isInline: false,
};

export default MemberName;
